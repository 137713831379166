import React, { useRef, useEffect } from 'react'
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, ChartOptions, ChartData } from 'chart.js';
import 'chart.js/auto';
import {
    Box,
  } from "@mui/material";
import { LatestPitchDeck, TimeSpentPerSlide } from '../../../../../types/dashboard';

interface IBarchartProps {
    dataChart: LatestPitchDeck
}

const BarChart: React.FC<IBarchartProps> = ({ dataChart }) => {
  const formatTime = (seconds: number | string) => {
    if (typeof seconds === 'string') {
      seconds = Math.floor(parseInt(seconds));
    }else {
      seconds = Math.floor(seconds);
    }

    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = remainingSeconds.toString().padStart(2, '0');

    return `${formattedMinutes}:${formattedSeconds}`;
  }

    // const data: number[] = dataChart.map((pitchDeck: LatestPitchDeck) => pitchDeck.timeSpend);
    const data: number[] | undefined = dataChart?.timeSpentPerSlide?.map((pitchDeck: TimeSpentPerSlide) => pitchDeck.time);
    const maxValue = Math.max(...data ?? []);
    const chartData: ChartData<'bar'> = {
        labels: dataChart?.timeSpentPerSlide?.map((pitchDeck: TimeSpentPerSlide) => `Slide ${pitchDeck.slide}`),
        datasets: [
            {
                label: '',
                data: data ?? [],
                backgroundColor: '#9B59B6',
                borderRadius: 20,
                datalabels: {
                    display: false,
                },
            },
        ],
    };

    const options: ChartOptions<'bar'> = {
        scales: {
            y: {
                beginAtZero: true,
                grid: {
                    display: false,
                },
                suggestedMin: 0,
                suggestedMax: maxValue,
                ticks: {
                    callback: function(value, index, values) {
                      return formatTime(value);
                    }
                }
            },
            x: {
                grid: {
                    display: false,
                }
            },
        },
        responsive: true,
        backgroundColor: 'none',
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: false, // Disable the default tooltip
                external: (context) => {
                    // Custom tooltip
                    const tooltipModel = context.tooltip;

                    // Tooltip Element
                    let tooltipEl = document.getElementById('chartjs-tooltip');

                    // Create element on first render
                    if (!tooltipEl) {
                        tooltipEl = document.createElement('div');
                        tooltipEl.id = 'chartjs-tooltip';
                        tooltipEl.style.opacity = '0';
                        tooltipEl.style.position = 'absolute';
                        tooltipEl.style.color = 'black';
                        tooltipEl.style.fontWeight = 'bold';
                        tooltipEl.style.borderRadius = '3px';
                        tooltipEl.style.pointerEvents = 'none';
                        tooltipEl.style.transform = 'translate(-50%, 0)';
                        tooltipEl.style.transition = 'opacity 0.3s ease';

                        document.body.appendChild(tooltipEl);
                    }

                    // Hide if no tooltip
                    if (tooltipModel.opacity === 0) {
                        tooltipEl.style.opacity = '0';
                        return;
                    }

                    // Set caret position
                    tooltipEl.classList.remove('above', 'below', 'no-transform');
                    if (tooltipModel.yAlign) {
                        tooltipEl.classList.add(tooltipModel.yAlign);
                    } else {
                        tooltipEl.classList.add('no-transform');
                    }

                    function getBody(bodyItem: any) {
                        return bodyItem.lines;
                    }

                    // Set text
                    if (tooltipModel.body) {
                        // const titleLines = tooltipModel.title || [];
                        const bodyLines = tooltipModel.body.map(getBody);

                        let innerHtml = '<div>';

                        // titleLines.forEach(function (title) {
                        //     innerHtml += '<div style="font-weight: bold;">' + title + '</div>';
                        // });

                        bodyLines.forEach(function (body, i) {
                            innerHtml += '<div>' + formatTime(body) + '</div>';
                        });
                        innerHtml += '</div>';

                        tooltipEl.innerHTML = innerHtml;
                    }

                    const position = context.chart.canvas.getBoundingClientRect();

                    tooltipEl.style.opacity = '1';
                    tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
                    tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY - tooltipModel.height + 'px';
                    tooltipEl.style.padding = 20 + 'px ' + 20 + 'px';
                }
            }
        },
    };

    const chartRef = useRef<ChartJS<'bar'>>(null);

    return (
      <Box
        mt={2}
        position="relative"
        borderRadius="15px"
      >
        <div>&nbsp;</div>
        <Bar ref={chartRef} data={chartData} options={options} />
      </Box>
    )
}

export default BarChart;