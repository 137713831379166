import React, { FC, useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import AccordionDetails from "@mui/material/AccordionDetails";
import { AiFillDelete } from "react-icons/ai";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { MdAccessTime } from "react-icons/md";
import { Grid, Box, Container, useMediaQuery, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import { BiSolidPencil } from "react-icons/bi";
import Chip from "@mui/material/Chip";
import { BsPatchCheck } from "react-icons/bs";
import { NumericFormat } from "react-number-format";
import Swal from "sweetalert2";
import MainCard from "../../../../../components/components/MainCard";
import { Paragraph } from "../../../../../styled/Paragraph";
// custom components
import {
  getAllInvestmentsRound,
  deleteRoundType,
  deleteInvestorFromRound,
} from "../../../../../helpers/Api";
import InvestorFoundingInnerModal from "../ModalChoice/Modals/InvestorFoundingInnerModal";
import RoundTrackInnerModal from "../ModalChoice/Modals/RoundTrackInnerModal";
import EditRoundTrackInnerModal from "../ModalChoice/Modals/EditRoundTrackInnerModal";
import EditInvestorFoundingInnerModal from "../ModalChoice/Modals/EditInvestorFoundingInnerModal";

type IdProp = {
  title: string;
  userId: string;
  isReadOnly?: boolean;
  jsonData?: any;
};

type EditRoundProps = {
  amount: number | null | undefined;
  date: Date | null | undefined;
  raisedAmount: number | null | undefined;
  roundType: string | null | undefined;
};

type CreateRoundProps = {
  roundType: string | null | undefined;
  amount: number | null | undefined;
  raisedAmount: number | null | undefined;
  date: Date | null | undefined;
  roundId: number | null | undefined;
  investors: createInvestorProps[];
};

type createInvestorProps = {
  id: number | null | undefined;
  firstName: string | null | undefined;
  lastName: string | null | undefined;
  firmName: string | null | undefined;
  email: string | null | undefined;
  amountInvested: number | null | undefined;
  investedType: any;
  isVerificated?: string | null | undefined;
};

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const CardInvestment: FC<IdProp> = ({
  title,
  userId,
  isReadOnly = false,
  jsonData,
}) => {
  const startupId = userId.toString();
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // Data State
  const [data, setData] = useState<CreateRoundProps[]>([]);
  const [dataToEditRound, setDataToEditRound] = useState<EditRoundProps>({
    amount: 0,
    date: null,
    raisedAmount: 0,
    roundType: "",
  });
  const [dataToEditInvestor, setDataToEditInvestor] =
    useState<createInvestorProps>({
      id: 0,
      firstName: "",
      lastName: "",
      firmName: "",
      email: "",
      amountInvested: 0,
      investedType: "",
      isVerificated: "pending",
    });
  const [roundId, setRoundId] = useState<number>(0);

  // Modal State
  const [addInvestorModalIsOpen, setAddInvestorModalIsOpen] =
    useState<boolean>(false);
  const [addRoundModalIsOpen, setAddRoundModalIsOpen] =
    useState<boolean>(false);
  const [modalEditRoundIsOpen, setModalEditRoundIsOpen] =
    useState<boolean>(false);
  const [modalEditInvestorIsOpen, setModalEditInvestorIsOpen] =
    useState<boolean>(false);

  // Modal Handlers
  const openEditInvestorModal = () => {
    setModalEditInvestorIsOpen(true);
  };

  const closeEditInvestorModal = () => {
    setDataToEditInvestor({
      id: 0,
      firstName: "",
      lastName: "",
      firmName: "",
      email: "",
      amountInvested: 0,
      investedType: "",
    });
    setModalEditInvestorIsOpen(false);
  };

  const closeAddInvestorModal = () => {
    setDataToEditInvestor({
      id: 0,
      firstName: "",
      lastName: "",
      firmName: "",
      email: "",
      amountInvested: 0,
      investedType: "",
    });
    setAddInvestorModalIsOpen(false);
  };

  const closeEditRoundModal = () => {
    setModalEditRoundIsOpen(false);
  };
  const editInvestor = (value: createInvestorProps, roundId: number) => {
    setDataToEditInvestor(value);
    setRoundId(roundId);
    openEditInvestorModal();
  };
  const editInvestmentRound = (value: EditRoundProps, roundId: number) => {
    setRoundId(roundId);
    setDataToEditRound(value);
    setModalEditRoundIsOpen(true);
  };

  const openAddInvestorModal = (roundId: number) => {
    setRoundId(roundId);
    setAddInvestorModalIsOpen(true);
  };

  const newInvestmentRound = () => {
    setAddRoundModalIsOpen(true);
  };

  const closeRoundModal = () => {
    setAddRoundModalIsOpen(false);
  };

  useEffect(() => {
    if (!isReadOnly) {
      const getRoundsData = async (id: string) => {
        const response = await getAllInvestmentsRound(id);
        if (response) {
          setData(response);
          setLoading(true);
        } else setData([]);
      };
      getRoundsData(startupId);
    } else {
      // Fill data from another component
      setData(jsonData?.InvestmentRounds || []);
      setLoading(true);
    }
  }, [startupId, loading, isReadOnly, jsonData?.InvestmentRounds]);

  const handleDeleteInvestor = async (roundId: number, investorId: number) => {
    Swal.fire({
      title: "Are you sure to delete this investor?",
      text: "It will be permanently deleted!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Delete!",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await deleteInvestorFromRound(
            startupId,
            roundId,
            investorId
          );
          if (response) {
            setLoading(false);
            Swal.fire(
              "Success",
              "The investor was deleted successfully",
              "success"
            );
          }
        } catch (error) {
          Swal.fire(
            "Oops",
            "There is a problem to delete the investor, please try again later",
            "error"
          );
        }
      }
    });
  };

  const handleDeleteRound = async (roundId: number) => {
    Swal.fire({
      title: "Are you sure to delete this profile?",
      text: "It will be permanently deleted!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Delete!",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await deleteRoundType(startupId, roundId);
          if (response) {
            setLoading(false);
            Swal.fire(
              "Success",
              "The round was deleted successfully",
              "success"
            );
          }
        } catch (error) {
          Swal.fire(
            "Oops",
            "There is a problem to delete the round, please try again later",
            "error"
          );
        }
      }
    });
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="stretch"
    >
      <Grid item md={12}>
        <MainCard title="Investment">
          <Grid container>
            <Grid
              item
              container
              md={12}
              p={2}
              display="flex"
              direction="column"
              alignItems="center"
            >
              {!loading ? (
                <Paragraph>Loading...</Paragraph>
              ) : (
                data?.map((value, index) => (
                  <Accordion
                    key={index}
                    defaultExpanded={index === 0}
                    sx={{
                      "&.MuiAccordion-root": { borderRadius: "15px" },
                      width: "100%",
                      boxShadow:
                        "0px -3.792px 151.692px 0px rgba(4, 191, 123, 0.07), 0px -1.584px 63.373px 0px rgba(4, 191, 123, 0.05), 0px -0.847px 33.882px 0px rgba(4, 191, 123, 0.04), 0px -0.475px 18.994px 0px rgba(4, 191, 123, 0.04), 0px -0.252px 10.088px 0px rgba(4, 191, 123, 0.03), 0px -0.105px 4.198px 0px rgba(4, 191, 123, 0.02)",
                    }}
                  >
                    <AccordionSummary
                      aria-controls="panel1d-content"
                      id="panel1d-header"
                    >
                      <Grid
                        justifyContent="space-between"
                        container
                        direction="row"
                        alignItems="center"
                      >
                        <Box display="flex">
                          <Paragraph
                            fontSize={20}
                            fontWeight="500"
                            color="#048C4D"
                            style={{ margin: "0px 0px 0px 20px" }}
                          >
                            {value.roundType}
                          </Paragraph>
                          <Paragraph
                            fontSize={12}
                            style={{ margin: "7px 0px 0px 10px" }}
                          >
                            ({value.date?.toString()?.slice(0, 10)})
                          </Paragraph>
                        </Box>
                        <Box>
                          <Paragraph
                            fontSize={12}
                            style={{ margin: "0px 0px 0px 20px" }}
                          >
                            <NumericFormat
                              type="text"
                              prefix={"$"}
                              suffix={" USD"}
                              decimalSeparator={"."}
                              thousandSeparator={true}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              placeholder="$0.0"
                              value={value?.amount || 0}
                              style={{ border: "none", background: "none" }}
                              disabled
                            />
                          </Paragraph>
                        </Box>

                        <Box display="flex" mr={2}>
                          {!isReadOnly && (
                            <button
                              onClick={() =>
                                handleDeleteRound(value?.roundId || 0)
                              }
                              className="button__delete color__active"
                              style={{
                                backgroundColor: "red", // Red
                                borderRadius: "8px",
                                padding: "8px",
                                marginRight: "10px",
                                cursor: "pointer",
                                transition: "background-color 0.3s ease",
                              }}
                            >
                              <AiFillDelete size={20} color="#FFF" />
                            </button>
                          )}
                          {!isReadOnly && (
                            <button
                              onClick={() =>
                                editInvestmentRound(value, value.roundId || 0)
                              }
                              className="button__edit color__active"
                              style={{
                                backgroundColor: "#04BF7B", // Green
                                borderRadius: "8px",
                                padding: "8px",
                                cursor: "pointer",
                                transition: "background-color 0.3s ease",
                              }}
                            >
                              <BiSolidPencil size={22} color="#FFF" />
                            </button>
                          )}
                        </Box>
                      </Grid>
                    </AccordionSummary>

                    <AccordionDetails>
                      <Grid container direction="row" p={1}>
                        <Grid item xs={6} md={6}>
                          <Box pb={isSmallScreen ? 0.5 : 4}>
                            <Paragraph fontSize={18} fontWeight="400">
                              Seed Investment
                            </Paragraph>
                          </Box>
                          <Box>
                            <Paragraph fontSize={18} fontWeight="400">
                              Raised till date
                            </Paragraph>
                          </Box>
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <Box pb={4}>
                            <NumericFormat
                              type="text"
                              prefix={"$"}
                              suffix={" USD"}
                              decimalSeparator={"."}
                              thousandSeparator={true}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              placeholder="$0.0"
                              value={value?.amount || 0}
                              style={{
                                border: "none",
                                background: "none",
                              }}
                              disabled
                            />
                          </Box>
                          <Box>
                            <NumericFormat
                              type="text"
                              prefix={"$"}
                              suffix={" USD"}
                              decimalSeparator={"."}
                              thousandSeparator={true}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              placeholder="$0.0"
                              value={value?.raisedAmount || 0}
                              style={{
                                border: "none",
                                background: "none",
                              }}
                              disabled
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box mt={2}>
                            <Paragraph fontSize={18} fontWeight="400">
                              Investors:
                            </Paragraph>
                          </Box>
                          <Container maxWidth="lg">
                            {value?.investors?.map(
                              (
                                investor: createInvestorProps,
                                index: number
                              ) => (
                                <Chip
                                  key={index}
                                  sx={{
                                    margin: "10px",
                                    borderColor: "#04BF7B",
                                  }}
                                  label={
                                    <span>
                                      <Paragraph fontSize={16}>
                                        {investor?.isVerificated ===
                                        "verified" ? (
                                          <BsPatchCheck color="#04BF7B" />
                                        ) : (
                                          <MdAccessTime color="#FF0000" />
                                        )}{" "}
                                        {investor?.firmName}
                                      </Paragraph>
                                    </span>
                                  }
                                  variant="outlined"
                                  {...(!isReadOnly && {
                                    onClick: () =>
                                      editInvestor(
                                        investor,
                                        value.roundId || 0
                                      ),
                                  })}
                                  {...(!isReadOnly &&
                                  investor.isVerificated !== "verified"
                                    ? {
                                        onDelete: () =>
                                          handleDeleteInvestor(
                                            value?.roundId || 0,
                                            investor?.id || 0
                                          ),
                                      }
                                    : {})}
                                />
                              )
                            )}
                            {!isReadOnly && (
                              <button
                                style={{ marginTop: "2rem" }}
                                onClick={() =>
                                  openAddInvestorModal(value.roundId || 0)
                                }
                                className="button__action_outline"
                              >
                                Edit Investor
                              </button>
                            )}
                          </Container>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                ))
              )}
            </Grid>
            <Grid
              container
              direction="column"
              alignItems="flex-start"
              mt={2}
              p={2}
            >
              <Grid item md={12}>
                {!isReadOnly && (
                  <button
                    className="button__action color__active"
                    onClick={newInvestmentRound}
                  >
                    <BsFillPlusCircleFill style={{ marginRight: "8px" }} />
                    add round
                  </button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </MainCard>
      </Grid>
      <InvestorFoundingInnerModal
        setLoading={setLoading}
        modalIsOpen={addInvestorModalIsOpen}
        startupId={startupId}
        roundId={roundId}
        closeModal={closeAddInvestorModal}
      />
      <EditInvestorFoundingInnerModal
        setLoading={setLoading}
        modalIsOpen={modalEditInvestorIsOpen}
        startupId={startupId}
        roundId={roundId}
        closeModal={closeEditInvestorModal}
        dataToEditInvestor={dataToEditInvestor}
      />
      <RoundTrackInnerModal
        setLoading={setLoading}
        modalIsOpen={addRoundModalIsOpen}
        closeModal={closeRoundModal}
        userId={userId}
      />
      <EditRoundTrackInnerModal
        setLoading={setLoading}
        roundId={roundId}
        dataToEditRound={dataToEditRound}
        modalIsOpen={modalEditRoundIsOpen}
        closeModal={closeEditRoundModal}
        userId={userId}
      />
    </Grid>
  );
};

export default CardInvestment;
