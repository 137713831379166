import React, {
  FC,
  useEffect,
  useState,
  useRef,
  useCallback,
  useContext,
} from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { saveAs } from "file-saver";
import { Grid, Box } from "@mui/material";
import { BiSolidPencil } from "react-icons/bi";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { MdOutlineZoomOutMap } from "react-icons/md";
import CircularProgress from "@mui/material/CircularProgress";
import EditPitchDeckInnerModal from "../ModalChoice/Modals/EditPitchDeckInnerModal";
import { getCookiesData } from "../../../../../helpers/Cookie";
import {
  trackClickPitchDeck,
  trackDeckDownload,
} from "../../../../../helpers/Api";
import { AppContext } from "../../../../../context";
import { FaRegFilePdf } from "react-icons/fa6";
import { FiZoomIn } from "react-icons/fi";
import MainCard from "../../../../../components/components/MainCard";
import { Paragraph } from "../../../../../styled/Paragraph";

// Setting up PDF.js worker source
// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   "pdfjs-dist/legacy/build/pdf.worker.min.mjs",
//   import.meta.url
// ).toString();
pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.min.mjs`;

interface CardChoosingProps {
  userId: number | string | null | undefined;
  startUpId?: number | string | null | undefined;
  urlPitch: string;
  isReadOnly?: boolean;
}

interface TrackEventDataDeckClick {
  email_as_user_id: string | null | undefined;
  user_browser: string | null | undefined;
  user_os: string | null | undefined;
  user_device_type: string | null | undefined;
  startup_id: number | string | null | undefined;
  total_time: number | null | undefined;
  number_slides_viewed: number | null | undefined;
  time_spent_per_slide: any;
}

interface TrackEventDataDeckDowload {
  email_as_user_id: string | null | undefined;
  user_browser: string | null | undefined;
  user_os: string | null | undefined;
  user_device_type: string | null | undefined;
  startup_id: number | string | null | undefined;
  deck_download_yes: boolean | null | undefined;
}

const CardPitchDeck: FC<CardChoosingProps> = ({
  userId,
  startUpId,
  urlPitch,
  isReadOnly = false,
}) => {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [numPages, setNumPages] = useState<number | null>(null);
  const [cachedPages, setCachedPages] = useState<{
    [key: number]: JSX.Element;
  }>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [startTime, setStartTime] = useState<number>(Date.now());
  const [pageTimes, setPageTimes] = useState<{ [key: number]: number }>({});
  const [totalTime, setTotalTime] = useState<number>(0);
  const [slidesViewed, setSlidesViewed] = useState<Set<number>>(new Set());

  const { getStartupProfile } = useContext(AppContext);
  const documentRef = useRef<typeof Document>(null);

  useEffect(() => {
    setCachedPages({});
  }, [urlPitch]);

  useEffect(() => {
    setStartTime(Date.now());
  }, [pageNumber]);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
    setLoading(false);
    preloadAdjacentPages(pageNumber + 5);
  };

  const preloadAdjacentPages = (currentPage: number) => {
    const pagesToPreload = [currentPage - 2, currentPage, currentPage + 5];
    if (numPages === null) return;

    pagesToPreload.forEach((pageNum) => {
      if (pageNum > 0 && pageNum <= numPages && !cachedPages[pageNum]) {
        setCachedPages((prev) => ({
          ...prev,
          [pageNum]: (
            <Page
              key={pageNum}
              pageNumber={pageNum}
              renderAnnotationLayer={false}
              renderTextLayer={false}
              className="buildProfile__pdfViewer"
            />
          ),
        }));
      }
    });
  };

  const goToPrevPage = () => {
    setPageNumber((prevPageNumber) => {
      const newPageNumber = Math.max(prevPageNumber - 1, 1);
      preloadAdjacentPages(newPageNumber);
      return newPageNumber;
    });
  };

  const goToNextPage = () => {
    setPageNumber((prevPageNumber) => {
      const newPageNumber =
        numPages !== null
          ? Math.min(prevPageNumber + 1, numPages)
          : prevPageNumber;
      preloadAdjacentPages(newPageNumber);
      return newPageNumber;
    });
  };

  const trackDeckDowload = async () => {
    const cookiesData = getCookiesData();
    const data: TrackEventDataDeckDowload = {
      email_as_user_id: cookiesData.email,
      user_browser: cookiesData.browser,
      user_os: cookiesData.os,
      user_device_type: cookiesData.deviceType,
      startup_id: startUpId,
      deck_download_yes: true,
    };
    await trackDeckDownload(data);
  };

  const handleDownload = async (fileToDownload: string) => {
    saveAs(fileToDownload, `pumpjuice-${fileToDownload}.pdf`);
    if (isReadOnly) {
      await trackDeckDowload();
    }
  };

  const loadingMessage = () => (
    <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
      <CircularProgress size={40} color="inherit" />
    </Box>
  );

  const openModal = () => {
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
    getStartupProfile();
  };

  const sendTrackingData = useCallback(async () => {
    const cookiesData = getCookiesData();
    const data: TrackEventDataDeckClick = {
      email_as_user_id: cookiesData.email,
      user_browser: cookiesData.browser,
      user_os: cookiesData.os,
      user_device_type: cookiesData.deviceType,
      startup_id: startUpId,
      total_time: totalTime,
      number_slides_viewed: slidesViewed.size,
      time_spent_per_slide: pageTimes,
    };
    await trackClickPitchDeck(data);
  }, [pageTimes, slidesViewed.size, startUpId, totalTime]);

  useEffect(() => {
    const trackInteraction = () => {
      const currentTime = Date.now();
      const timeSpent = currentTime - startTime;
      setTotalTime((prevTotalTime) => prevTotalTime + timeSpent);
      setPageTimes((prevPageTimes) => ({
        ...prevPageTimes,
        [pageNumber]: (prevPageTimes[pageNumber] || 0) + timeSpent,
      }));
      setSlidesViewed((prevSlidesViewed) =>
        new Set(prevSlidesViewed).add(pageNumber)
      );
    };

    const handleBeforeUnload = async () => {
      if (isReadOnly) {
        await sendTrackingData();
      }
    };

    window.addEventListener("beforeunload", trackInteraction);
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      trackInteraction();
      window.removeEventListener("beforeunload", trackInteraction);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, startTime, isReadOnly]);

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="stretch"
    >
      <Grid item md={12}>
        <MainCard title="Pitch Deck">
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              mb: 2,
            }}
          >
            <button
              onClick={() => handleDownload(urlPitch)}
              className="buildProfile__pdfViewer_button"
            >
              <FaRegFilePdf color="#04BF7B" size={27} />
            </button>
            <button
              onClick={() => window.open(urlPitch)}
              className="buildProfile__pdfViewer_button"
            >
              <MdOutlineZoomOutMap color="#04BF7B" size={30} />
            </button>
            {!isReadOnly && (
              <button
                onClick={openModal}
                className="button__edit color__active"
                style={{
                  backgroundColor: "#04BF7B",
                  borderRadius: "8px",
                  padding: "8px",
                  cursor: "pointer",
                  transition: "background-color 0.3s ease",
                }}
              >
                <BiSolidPencil size={22} />
              </button>
            )}
          </Box>

          <Grid
            display={"flex"}
            direction="row"
            justifyContent="center"
            alignItems="center"
            item
            xs={12}
          >
            <button
              className="button__link"
              onClick={goToPrevPage}
              disabled={pageNumber <= 1}
            >
              <IoIosArrowBack size={40} />
            </button>
            <Document
              loading={loadingMessage}
              file={urlPitch}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              {cachedPages[pageNumber] || (
                <Page
                  renderAnnotationLayer={false}
                  renderTextLayer={false}
                  className="buildProfile__pdfViewer"
                  pageNumber={pageNumber}
                />
              )}
            </Document>
            <button
              className="button__link"
              onClick={goToNextPage}
              disabled={pageNumber === numPages}
            >
              <IoIosArrowForward size={40} />
            </button>
          </Grid>

          <Grid
            display={"flex"}
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            item
            xs={12}
          >
            <Paragraph fontSize={14}>
              Page {pageNumber} of {numPages}
            </Paragraph>
          </Grid>
        </MainCard>
      </Grid>
      <EditPitchDeckInnerModal
        modalIsOpen={modalIsOpen}
        startupId={userId}
        closeModal={closeModal}
      />
    </Grid>
  );
};

export default CardPitchDeck;
