import React, { FC, useContext, useState } from "react";
import { Box, Grid } from "@mui/material";
import { Formik, Form, ErrorMessage } from "formik";
import Modal from "react-modal";
import Swal from "sweetalert2";
import { LiaTimesSolid } from "react-icons/lia";
//API calls
import { editStartupDescription } from "../../../../../../helpers/Api";
//Customs components
import CustomTextField from "../../../../../../components/components/CustomTextField";
import { AppContext } from "../../../../../../context";

type DescriptionInputProps = {
  modalIsOpen: boolean;
  closeModal: () => void;
  description: string | null | undefined;
  userId: string | number | null | undefined;
};

type DescriptionProps = {
  description: string | null | undefined;
};

const StartupDescriptionInnerModal: FC<DescriptionInputProps> = ({
  modalIsOpen,
  closeModal,
  description,
  userId,
}) => {
  const { getStartupProfile } = useContext(AppContext);

  const startupId = userId?.toString() || "";

  const initialValues: DescriptionProps = {
    description: description || "",
  };

  const handleDescription = async (values: DescriptionProps) => {
    try {
      const response = await editStartupDescription(
        {
          description: values.description,
        },
        startupId
      );
      if (response === 200) {
        Swal.fire(
          "Success",
          "Amazing you've added the startup description",
          "success"
        );
        closeModal();
      }
      getStartupProfile();
    } catch (error) {
      Swal.fire(
        "Oops",
        `Looks like there is something wrong <br />
          `,
        "error"
      );
    }
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      className="Modal"
      overlayClassName="Overlay"
      contentLabel="Choice Modal"
      ariaHideApp={false}
    >
      <Grid
        container
        display="flex"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        p={"1rem"}
        color={"white"}
        bgcolor={"#012626"}
      >
        <Box>
          <p className="buildProfile__modal_paragraph">Your startup story</p>
        </Box>
        <Box>
          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              padding: "10px",
            }}
            onClick={closeModal}
          >
            <LiaTimesSolid size={32} color="#04BF7B" />
          </button>
        </Box>
      </Grid>
      <Grid
        p={4}
        container
        display="flex"
        direction="column"
        alignItems="stretch"
      >
        <Formik
          initialValues={initialValues}
          onSubmit={(values: DescriptionProps) => {
            handleDescription(values);
          }}
        >
          {(props) => (
            <Form>
              <CustomTextField
                name="description"
                isMultiline={true}
                placeholderMessage="Tell us about your startup. What problem are you solving? What makes your startup unique?"
                rowsNumber={10}
                sx={{
                  borderRadius: "15px",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "15px",
                  },
                  "& fieldset": {
                    borderRadius: "15px",
                  },
                }}
              />
              <ErrorMessage
                name="description"
                component="p"
                className="requiredField"
              />
              <Box mt={6} textAlign={"center"}>
                <button type="submit" className="button__primary color__active">
                  Submit
                </button>
              </Box>
            </Form>
          )}
        </Formik>
      </Grid>
    </Modal>
  );
};

export default StartupDescriptionInnerModal;
