import { Accordion, AccordionDetails, AccordionSummary, Avatar, Grid } from '@mui/material'
import React, { useState } from 'react'
import { Paragraph } from '../../../../../styled/Paragraph'
import InvestorCard from '../InvestorCard/InvestorCard'
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Investor } from '../../../../../types/fundraising';

const checkSize = '$50k - 150k'

interface Props {
  investor: Investor
  isFavList: boolean
  updateData?: (searchQuery: string) => Promise<void>
}

const InvestorItemList = ({ investor, isFavList = false, updateData }: Props) => {
  const { first_name, last_name, industry, firm_name, fund_stage, isSaved } = investor
  const [isInvSaved, setIsInvSaved] = useState<boolean>(isSaved)
  const getInitials = (firstName: string, lastName: string) => {
    const firstInitial = firstName ? firstName[0].toUpperCase() : '';
    const lastInitial = lastName ? lastName[0].toUpperCase() : '';
    return `${firstInitial}${lastInitial}`;
  };

  return (
    <Accordion
    key={1}
    sx={{
      "&.MuiAccordion-root": {
        borderRadius: "8px",
        position: 'static'
      },
      width: "100%",
      marginTop: 1,
      boxShadow:
        "0px -2px 80px 0px rgba(0, 0, 0, 0.07), 0px -0.836px 33.422px 0px rgba(0, 0, 0, 0.05), 0px -0.447px 17.869px 0px rgba(0, 0, 0, 0.04), 0px -0.25px 10.017px 0px rgba(0, 0, 0, 0.04), 0px -0.133px 5.32px 0px rgba(0, 0, 0, 0.03), 0px -0.055px 2.214px 0px rgba(0, 0, 0, 0.02)",
    }}
  >
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel1d-content"
      id="panel1d-header"
      sx={{
        '& .MuiAccordionSummary-content': {
          margin: '0',
          width: '90%'
        },
        '&.Mui-expanded .MuiAccordionSummary-content': {
          margin: '0',
        },
      }}
    >
      <Grid container direction="row" sx={{ alignItems: 'center', padding: 0, justifyContent: 'center' }} spacing={2} wrap="wrap">
  <Grid item xs={12} sm={4} sx={{ display: 'flex', gap: 1, alignItems: 'center', justifyContent: { xs: 'center', sm: 'start' } }}>
    {/* <Avatar>{getInitials(first_name, last_name)}</Avatar> */}
    <div>
      <Paragraph fontSize={16} fontWeight='400' style={{ lineHeight: '1.1'}}>{first_name} {last_name}</Paragraph>
      <Paragraph fontSize={14} fontWeight='300' style={{ lineHeight: '1.1' }}>{firm_name}</Paragraph>
    </div>
  </Grid>
  <Grid item xs={12} sm={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <Paragraph ellipsis fontSize={16} fontWeight='400' color='#035928' style={{ textAlign: 'center' }}>{fund_stage.replace(/,/g, ', ')}</Paragraph>
  </Grid>
  <Grid item xs={12} sm={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <Paragraph ellipsis fontSize={16} fontWeight='400' style={{ textAlign: 'center' }}>{industry.replace(/,/g, ', ')}</Paragraph>
  </Grid>
  <Grid item xs={12} sm={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    {isFavList ? (
      <div style={{ textAlign: 'center', color: '#1E1E1E' }}>View details</div>
    ) : (
      <div style={{ textAlign: 'center', color: isInvSaved ? '#048C4D' : '#1E1E1E' }}>{isInvSaved ? 'Saved' : 'Save to List' }</div>
    )}
  </Grid>
</Grid>

    </AccordionSummary>
    <AccordionDetails sx={{ padding: '0' }}>
      <InvestorCard
        boxShadow={false}
        isInvestor={false}
        checkSize={checkSize}
        investor={investor}
        isFavList={isFavList}
        isSaved={isInvSaved}
        setIsSaved={setIsInvSaved}
        updateData={updateData}
      />
    </AccordionDetails>
  </Accordion>
  )
}

export default InvestorItemList