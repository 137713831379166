export const IndustryCollection = [
  { value: "Accounting", label: "Accounting" },
  { value: "Airlines/Aviation", label: "Airlines/Aviation" },
  {
    value: "Alternative Dispute Resolution",
    label: "Alternative Dispute Resolution",
  },
  { value: "Alternative Medicine", label: "Alternative Medicine" },
  { value: "Animation", label: "Animation" },
  { value: "Apparel & Fashion", label: "Apparel & Fashion" },
  { value: "Architecture & Planning", label: "Architecture & Planning" },
  { value: "Arts & Crafts", label: "Arts & Crafts" },
  { value: "Automotive", label: "Automotive" },
  { value: "Aviation & Aerospace", label: "Aviation & Aerospace" },
  { value: "Banking", label: "Banking" },
  { value: "Biotechnology", label: "Biotechnology" },
  { value: "Broadcast Media", label: "Broadcast Media" },
  { value: "Building Materials", label: "Building Materials" },
  {
    value: "Business Supplies & Equipment",
    label: "Business Supplies & Equipment",
  },
  { value: "Capital Markets", label: "Capital Markets" },
  { value: "Chemicals", label: "Chemicals" },
  {
    value: "Civic & Social Organization",
    label: "Civic & Social Organization",
  },
  { value: "Civil Engineering", label: "Civil Engineering" },
  { value: "Commercial Real Estate", label: "Commercial Real Estate" },
  {
    value: "Computer & Network Security",
    label: "Computer & Network Security",
  },
  { value: "Computer Games", label: "Computer Games" },
  { value: "Computer Hardware", label: "Computer Hardware" },
  { value: "Computer Networking", label: "Computer Networking" },
  { value: "Computer Software", label: "Computer Software" },
  { value: "Construction", label: "Construction" },
  { value: "Consumer Electronics", label: "Consumer Electronics" },
  { value: "Consumer Goods", label: "Consumer Goods" },
  { value: "Consumer Services", label: "Consumer Services" },
  { value: "Cosmetics", label: "Cosmetics" },
  { value: "Dairy", label: "Dairy" },
  { value: "Defense & Space", label: "Defense & Space" },
  { value: "Design", label: "Design" },
  { value: "Education Management", label: "Education Management" },
  { value: "E-Learning", label: "E-Learning" },
  {
    value: "Electrical/Electronic Manufacturing",
    label: "Electrical/Electronic Manufacturing",
  },
  { value: "Entertainment", label: "Entertainment" },
  { value: "Environmental Services", label: "Environmental Services" },
  { value: "Events Services", label: "Events Services" },
  { value: "Executive Office", label: "Executive Office" },
  { value: "Facilities Services", label: "Facilities Services" },
  { value: "Farming", label: "Farming" },
  { value: "Financial Services", label: "Financial Services" },
  { value: "Fine Art", label: "Fine Art" },
  { value: "Fishery", label: "Fishery" },
  { value: "Food & Beverages", label: "Food & Beverages" },
  { value: "Food Production", label: "Food Production" },
  { value: "Fundraising", label: "Fundraising" },
  { value: "Furniture", label: "Furniture" },
  { value: "Gambling & Casinos", label: "Gambling & Casinos" },
  { value: "Glass, Ceramics & Concrete", label: "Glass, Ceramics & Concrete" },
  { value: "Government Administration", label: "Government Administration" },
  { value: "Government Relations", label: "Government Relations" },
  { value: "Graphic Design", label: "Graphic Design" },
  { value: "Health, Wellness & Fitness", label: "Health, Wellness & Fitness" },
  { value: "Higher Education", label: "Higher Education" },
  { value: "Hospital & Health Care", label: "Hospital & Health Care" },
  { value: "Hospitality", label: "Hospitality" },
  { value: "Human Resources", label: "Human Resources" },
  { value: "Import & Export", label: "Import & Export" },
  {
    value: "Individual & Family Services",
    label: "Individual & Family Services",
  },
  { value: "Industrial Automation", label: "Industrial Automation" },
  { value: "Information Services", label: "Information Services" },
  {
    value: "Information Technology & Services",
    label: "Information Technology & Services",
  },
  { value: "Insurance", label: "Insurance" },
  { value: "International Affairs", label: "International Affairs" },
  {
    value: "International Trade & Development",
    label: "International Trade & Development",
  },
  { value: "Internet", label: "Internet" },
  { value: "Investment Banking", label: "Investment Banking" },
  { value: "Investment Management", label: "Investment Management" },
  { value: "Judiciary", label: "Judiciary" },
  { value: "Law Enforcement", label: "Law Enforcement" },
  { value: "Law Practice", label: "Law Practice" },
  { value: "Legal Services", label: "Legal Services" },
  { value: "Legislative Office", label: "Legislative Office" },
  { value: "Leisure, Travel & Tourism", label: "Leisure, Travel & Tourism" },
  { value: "Libraries", label: "Libraries" },
  { value: "Logistics & Supply Chain", label: "Logistics & Supply Chain" },
  { value: "Luxury Goods & Jewelry", label: "Luxury Goods & Jewelry" },
  { value: "Machinery", label: "Machinery" },
  { value: "Management Consulting", label: "Management Consulting" },
  { value: "Maritime", label: "Maritime" },
  { value: "Marketing & Advertising", label: "Marketing & Advertising" },
  { value: "Market Research", label: "Market Research" },
  {
    value: "Mechanical or Industrial Engineering",
    label: "Mechanical or Industrial Engineering",
  },
  { value: "Media Production", label: "Media Production" },
  { value: "Medical Devices", label: "Medical Devices" },
  { value: "Medical Practice", label: "Medical Practice" },
  { value: "Mental Health Care", label: "Mental Health Care" },
  { value: "Military", label: "Military" },
  { value: "Mining & Metals", label: "Mining & Metals" },
  { value: "Motion Pictures & Film", label: "Motion Pictures & Film" },
  { value: "Museums & Institutions", label: "Museums & Institutions" },
  { value: "Music", label: "Music" },
  { value: "Nanotechnology", label: "Nanotechnology" },
  { value: "Newspapers", label: "Newspapers" },
  {
    value: "Nonprofit Organization Management",
    label: "Nonprofit Organization Management",
  },
  { value: "Oil & Energy", label: "Oil & Energy" },
  { value: "Online Media", label: "Online Media" },
  { value: "Outsourcing/Offshoring", label: "Outsourcing/Offshoring" },
  { value: "Package/Freight Delivery", label: "Package/Freight Delivery" },
  { value: "Packaging & Containers", label: "Packaging & Containers" },
  { value: "Paper & Forest Products", label: "Paper & Forest Products" },
  { value: "Performing Arts", label: "Performing Arts" },
  { value: "Pharmaceuticals", label: "Pharmaceuticals" },
  { value: "Philanthropy", label: "Philanthropy" },
  { value: "Photography", label: "Photography" },
  { value: "Plastics", label: "Plastics" },
  { value: "Political Organization", label: "Political Organization" },
  {
    value: "Primary/Secondary Education",
    label: "Primary/Secondary Education",
  },
  { value: "Printing", label: "Printing" },
  {
    value: "Professional Training & Coaching",
    label: "Professional Training & Coaching",
  },
  { value: "Program Development", label: "Program Development" },
  { value: "Public Policy", label: "Public Policy" },
  {
    value: "Public Relations & Communications",
    label: "Public Relations & Communications",
  },
  { value: "Public Safety", label: "Public Safety" },
  { value: "Publishing", label: "Publishing" },
  { value: "Railroad Manufacture", label: "Railroad Manufacture" },
  { value: "Ranching", label: "Ranching" },
  { value: "Real Estate", label: "Real Estate" },
  {
    value: "Recreational Facilities & Services",
    label: "Recreational Facilities & Services",
  },
  { value: "Religious Institutions", label: "Religious Institutions" },
  { value: "Renewables & Environment", label: "Renewables & Environment" },
  { value: "Research", label: "Research" },
  { value: "Restaurants", label: "Restaurants" },
  { value: "Retail", label: "Retail" },
  { value: "Security & Investigations", label: "Security & Investigations" },
  { value: "Semiconductors", label: "Semiconductors" },
  { value: "Shipbuilding", label: "Shipbuilding" },
  { value: "Sporting Goods", label: "Sporting Goods" },
  { value: "Sports", label: "Sports" },
  { value: "Staffing & Recruiting", label: "Staffing & Recruiting" },
  { value: "Supermarkets", label: "Supermarkets" },
  { value: "Telecommunications", label: "Telecommunications" },
  { value: "Textiles", label: "Textiles" },
  { value: "Think Tanks", label: "Think Tanks" },
  { value: "Tobacco", label: "Tobacco" },
  { value: "Translation & Localization", label: "Translation & Localization" },
  {
    value: "Transportation/Trucking/Railroad",
    label: "Transportation/Trucking/Railroad",
  },
  { value: "Utilities", label: "Utilities" },
  {
    value: "Venture Capital & Private Equity",
    label: "Venture Capital & Private Equity",
  },
  { value: "Veterinary", label: "Veterinary" },
  { value: "Warehousing", label: "Warehousing" },
  { value: "Wholesale", label: "Wholesale" },
  { value: "Wine & Spirits", label: "Wine & Spirits" },
  { value: "Wireless", label: "Wireless" },
  { value: "Writing & Editing", label: "Writing & Editing" },
];
