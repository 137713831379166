import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { IoIosArrowRoundForward } from "react-icons/io";
import Avatar from '@mui/material/Avatar';
import { useEffect, useState } from 'react';
import { formatDistanceToNow } from 'date-fns';
import { RecentActivity } from '../../../../../types/dashboard';
import { getTimeAgo } from '../../../../../helpers/Utils';

interface Column {
  id: 'name' | 'total' | 'last';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: 'name', label: 'Visitors', minWidth: 170 },
  { id: 'total', label: 'Total Visits', minWidth: 100 },
  { id: 'last', label: 'Last Visit', minWidth: 170, align: 'right'}
];

interface Data {
    id:number,
    name: string,
    total: number,
    last: string,
}

function createData(
    id:number,
    name: string,
    total: number,
    last: string,
): Data {
  return {id, name, total, last };
}

interface VisitorListProps {
  visitors?: RecentActivity[]
}

const VisitorsList: React.FC<VisitorListProps> = ({ visitors }) => {

  const [rows, setRows] = useState<any[]>([])
  const navigate = useNavigate();

  useEffect(() => {
    const visitorRow = visitors?.map((v, i) => createData(i, v.email, v.totalVisits, getTimeAgo(v.lastVisit)))
    visitorRow && setRows(visitorRow)
  }, [visitors])

  const getInitials = (name: string): string => {
    const [firstName, lastName] = name.split(' ');
    const initials = firstName.charAt(0) + (lastName ? lastName.charAt(0) : '');
    return initials.toUpperCase();
  }

  return (
   <>
    <Box
    display={"flex"}
    justifyContent={"space-between"}
    alignItems={"center"}
    p={3}
    bgcolor={"#012626"}
    borderRadius={"15px 15px 0 0"}
    sx={{
      boxShadow:
      '0px -3.792px 151.692px 0px rgba(4, 191, 123, 0.07), 0px -1.584px 63.373px 0px rgba(4, 191, 123, 0.05), 0px -0.847px 33.882px 0px rgba(4, 191, 123, 0.04), 0px -0.475px 18.994px 0px rgba(4, 191, 123, 0.04), 0px -0.252px 10.088px 0px rgba(4, 191, 123, 0.03), 0px -0.105px 4.198px 0px rgba(4, 191, 123, 0.02)',
    }}
    >
    <p style={{
      color: "#04BF7B",
      fontSize: "24px",
      paddingTop: "0.6rem",
      fontWeight: "400",
      }}>
    Recent activity
    </p>
    <button
      style={{
        backgroundColor: "transparent",
        border: "none",
        color: "#04BF7B",
        fontWeight: "600",
      }}
        onClick={() => navigate('/dashboard/visitors')}
        >
        View More<IoIosArrowRoundForward size={32} color="#04BF7B" />
        </button>
      </Box>
      <Box sx={{ backgroundColor: 'white',
        boxShadow:
        '0px -3.792px 151.692px 0px rgba(4, 191, 123, 0.07), 0px -1.584px 63.373px 0px rgba(4, 191, 123, 0.05), 0px -0.847px 33.882px 0px rgba(4, 191, 123, 0.04), 0px -0.475px 18.994px 0px rgba(4, 191, 123, 0.04), 0px -0.252px 10.088px 0px rgba(4, 191, 123, 0.03), 0px -0.105px 4.198px 0px rgba(4, 191, 123, 0.02)',
        borderRadius: '0px 0px 15px 15px',
        minHeight: '397px'
      }}
      >
      <TableContainer sx={{ padding: 2}}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .map((row) => {
                return (
                  <TableRow
                  hover role="checkbox" tabIndex={-1} key={row.id}>
                    <TableCell>
                      <Box
                      display={"flex"}
                      flexDirection={"column"} // Cambiar de row a column
                      alignItems="flex-start"  // Alinea el texto a la izquierda
                      sx={{ wordBreak: 'break-all' }} // Permite el ajuste de línea
                      >
                      <Box display={"flex"} flexDirection={"row"} alignItems="center">
                        <Avatar sx={{marginRight: '6px', bgcolor: 'rgba(105, 226, 142, 0.6)'}}  alt={row.name} src="/static/images/avatar/1.jpg">{getInitials(row.name)}</Avatar>
                        {row.name}
                      </Box>
                      </Box>
                    </TableCell>
                    <TableCell>
                      {row.total}
                    </TableCell>
                    <TableCell align="right">
                      {row.last}
                    </TableCell>
                    {/* {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })} */}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      </Box>
    </>
  );
}

export default VisitorsList