import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { setCookie } from "../helpers/Cookie";

const BASE_URL: string = process.env.REACT_APP_API as string;

const instance: AxiosInstance = axios.create({
  baseURL: BASE_URL,
  timeout: 10000,
});

instance.interceptors.request.use((config: AxiosRequestConfig) => {
  const configuration: AxiosRequestConfig = config;
  setCookie(configuration);
  if (!configuration.url?.includes("/auth/")) {
    const token = localStorage.getItem("token");
    if (token) {
      if (!configuration.headers) {
        configuration.headers = {};
      }
      configuration.headers.Authorization = `Bearer ${token}`;
    }
  }
  return configuration;
});

// Add a response interceptor
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.clear();
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

export default instance;