import React, { useState, useEffect } from "react";
import { Button, Card, CardContent, Grid, Typography } from "@mui/material";
import Cookies from "js-cookie";
import CardEssentialInformation from "../../../../private/StartupProfile/components/StepCards/CardEssentialInformation";
import CardStartupDescription from "../../../../private/StartupProfile/components/StepCards/CardStartupDescription";
import CardFounders from "../../../../private/StartupProfile/components/StepCards/CardFounders";
import CardPitchDeck from "../../../../private/StartupProfile/components/StepCards/CardPitchDeck";
import CardInvestment from "../../../../private/StartupProfile/components/StepCards/CardInvestment";
import CardBusinessTraction from "../../../../private/StartupProfile/components/StepCards/CardBusinessTraction";
import CardVideo from "../../../../private/StartupProfile/components/StepCards/CardVideo";
import pomjuice_logo from "../../../../../assets/pompjuice_logo.svg";
import {
  trackClickPassStartup,
  trackClickConnectStartup,
  trackVisitStartupPage,
} from "../../../../../helpers/Api";
import { getCookiesData } from "../../../../../helpers/Cookie";
import { MdCancel, MdConnectWithoutContact } from "react-icons/md";
import { Params, useParams } from "react-router-dom";
import FeedbackForm from "./FeedbackForm";
import { PublicStartupResponse } from "../../../../../types/startup";
import ConnectModal from "./ConnectModal";

interface TrackVisitPage {
  email_as_user_id: string | null | undefined;
  user_browser: string | null | undefined;
  user_os: string | null | undefined;
  user_device_type: string | null | undefined;
  startup_id: number | null | undefined;
  time_spent_on_page: string | null | undefined;
}

interface TrackClickPass {
  email_as_user_id: string | null | undefined;
  user_browser: string | null | undefined;
  user_os: string | null | undefined;
  user_device_type: string | null | undefined;
  startup_id: number | null | undefined;
  pass_yes: boolean | null | undefined;
}

interface TrackClickConnect {
  email_as_user_id: string | null | undefined;
  user_browser: string | null | undefined;
  user_os: string | null | undefined;
  user_device_type: string | null | undefined;
  startup_id: number | null | undefined;
  connect_yes: boolean | null | undefined;
}

interface StartupProfileProps {
  jsonData: PublicStartupResponse;
  emailValue?: string | null;
  isReadOnly?: boolean;
}

const StartupProfile: React.FC<StartupProfileProps> = ({
  jsonData,
  emailValue,
  isReadOnly = true,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [modalType, setModalType] = useState<"connect" | "pass">();
  const { id } = useParams<Params>();

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  useEffect(() => {
    const startTime = Date.now();

    const sendTimeSpent = async () => {
      const endTime = Date.now();
      const timeSpent = (endTime - startTime) / 1000;
      const cookiesData = getCookiesData();
      const properties: TrackVisitPage = {
        email_as_user_id: cookiesData.email,
        user_browser: cookiesData.browser,
        user_os: cookiesData.os,
        user_device_type: cookiesData.deviceType,
        startup_id: jsonData?.id,
        time_spent_on_page: timeSpent.toString(),
      };
      await trackVisitStartupPage(properties);
    };

    window.addEventListener("beforeunload", sendTimeSpent);
    return () => {
      window.removeEventListener("beforeunload", sendTimeSpent);
    };
  }, [jsonData.id]);

  const trackPassed = async () => {
    const cookiesData = getCookiesData();
    const properties: TrackClickPass = {
      email_as_user_id: cookiesData.email,
      user_browser: cookiesData.browser,
      user_os: cookiesData.os,
      user_device_type: cookiesData.deviceType,
      startup_id: jsonData.id,
      pass_yes: true,
    };
    await trackClickPassStartup(properties);
  };

  const trackConnected = async () => {
    const cookiesData = getCookiesData();
    const properties: TrackClickConnect = {
      email_as_user_id: cookiesData.email,
      user_browser: cookiesData.browser,
      user_os: cookiesData.os,
      user_device_type: cookiesData.deviceType,
      startup_id: jsonData.id,
      connect_yes: true,
    };
    await trackClickConnectStartup(properties);
  };

  const handlePassStartUp = async () => {
    await trackPassed();
  };

  const handleConnectStartUp = async () => {
    setModalType("connect");
    openModal();
    await trackConnected();
  };

  const handleClick = () => {
    setModalType("pass");
    openModal();
    handlePassStartUp();
  };

  return (
    <>
      <Card variant="outlined" sx={cardStyles}>
        <CardContent sx={cardContentStyles}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <img
                src={pomjuice_logo}
                alt="PumpJuice Logo"
                style={logoStyles}
              />
            </Grid>
            <Grid item>
              <button
                type="submit"
                className="button__secondary color__active"
                style={{
                  backgroundColor: "#04BF7B",
                  color: "#fff",
                }}
                onClick={() => window.open("https://pumpjuice.com/")}
              >
                <p style={buttonTextStyles}> Get your own PomJuice page</p>
              </button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="stretch"
        sx={gridStyles}
      >
        <Grid item md={12} mt={8}>
          <CardEssentialInformation
            location={jsonData?.location}
            name={jsonData?.name}
            industry={jsonData?.industry}
            employeeCount={jsonData?.employeeCount}
            stage={jsonData?.stage}
            websiteUrl={jsonData?.websiteUrl}
            userId={jsonData?.id}
            imageUrl={jsonData?.imageUrl}
            isReadOnly={isReadOnly}
          />
        </Grid>
        <Grid item md={12} mt={8}>
          <CardStartupDescription
            description={jsonData?.description}
            title="Startup Description"
            userId={0}
            isReadOnly={isReadOnly}
          />
        </Grid>
        <Grid item md={12} mt={8}>
          <CardFounders
            userId={""}
            isReadOnly={isReadOnly}
            dataFounders={jsonData?.founders}
          />
        </Grid>
        <Grid item md={12} mt={8}>
          <CardBusinessTraction
            userId={""}
            title="Business Traction"
            dataTraction={{
              businessModel: jsonData?.businessTractions?.businessModel || "",
              businessRevenueSources:
                jsonData?.businessTractions?.businessRevenueSources || "",
              mrr: jsonData?.businessTractions?.mrr || 0,
              topCustomers: jsonData?.businessTractions?.topCustomers || [],
            }}
            isReadOnly={isReadOnly}
          />
        </Grid>
        <Grid item md={12} mt={8}>
          <CardInvestment
            title={"Investment"}
            userId={id ?? ""}
            isReadOnly={isReadOnly}
            jsonData={jsonData}
          />
        </Grid>
        <Grid item md={12} mt={8}>
          <CardPitchDeck
            startUpId={jsonData.id}
            userId={""}
            urlPitch={jsonData?.pitchDeckUrl}
            isReadOnly={isReadOnly}
          />
        </Grid>
        <Grid item md={12} mt={8}>
          <CardVideo
            id={""}
            startUpId={jsonData.id}
            videoUrl={jsonData?.videoUrl}
            isReadOnly={isReadOnly}
          />
        </Grid>
      </Grid>
      <CardContent sx={cardContentStyles}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid
            item
            xs={12}
            sm={6}
            md={5}
            lg={4}
            xl={3}
            sx={buttonContainerStyles}
          >
            <button
              type="submit"
              className="button__secondary "
              onClick={handleClick}
              style={{ backgroundColor: "#FF4B4B", color: "#fff" }}
            >
              <MdCancel style={{ marginRight: "5px", fontSize: "1.5rem" }} />
              Pass
            </button>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={5}
            lg={4}
            xl={3}
            sx={buttonContainerStyles}
          >
            <button
              type="submit"
              className="button__secondary color__active"
              style={{ backgroundColor: "#04BF7B", color: "#fff" }}
              onClick={handleConnectStartUp}
            >
              <MdConnectWithoutContact
                style={{ marginRight: "5px", fontSize: "1.5rem" }}
              />
              Connect
            </button>
          </Grid>
        </Grid>
        <FeedbackForm
          modalIsOpen={modalIsOpen && modalType === "pass"}
          closeModal={closeModal}
          companyName={jsonData?.name}
        />
        <ConnectModal
          modalIsOpen={modalIsOpen && modalType === "connect"}
          closeModal={closeModal}
          startupData={jsonData}
        />
      </CardContent>
    </>
  );
};

const cardStyles = {
  boxShadow:
    "0px -2px 80px 0px rgba(0, 0, 0, 0.07), 0px -0.836px 33.422px 0px rgba(0, 0, 0, 0.05), 0px -0.447px 17.869px 0px rgba(0, 0, 0, 0.04), 0px -0.25px 10.017px 0px rgba(0, 0, 0, 0.04), 0px -0.133px 5.32px 0px rgba(0, 0, 0, 0.03), 0px -0.055px 2.214px 0px rgba(0, 0, 0, 0.02)",
  borderRadius: "13px",
  overflow: "hidden",
  height: "100%",
  paddingLeft: "1rem",
};

const cardContentStyles = {
  "&:last-child": {
    paddingBottom: "40px",
  },
};

const logoStyles = {
  width: 150,
};

const buttonTextStyles: React.CSSProperties = {
  color: "#fff",
  fontSize: "15px",
  fontFamily: "Inter, sans-serif",
  fontWeight: 500,
  lineHeight: "16.8px",
  wordWrap: "break-word",
  textTransform: "none",
  paddingTop: "0.5rem",
};

const gridStyles = {
  padding: {
    xs: "4rem 2rem",
    sm: "4rem 4rem",
    md: "4rem 8rem",
    lg: "4rem 16rem",
    xl: "4rem 24rem",
  },
  width: "100%",
};

const buttonContainerStyles = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export default StartupProfile;
