import React, { useContext, FC, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Swal from "sweetalert2";
//imgs and icons
import Avatar from "@mui/material/Avatar";
import { IoIosPower } from "react-icons/io";
import { IoShareSocialOutline } from "react-icons/io5";
import PumpJuiceLogo from "../assets/pomjuice_twoColors.svg";
// custom components
import { AppContext } from "../context/index";
import { getBasicProfile } from "../helpers/Api";
import ButtonNavActive from "./components/ButtonNavActive";
import ModalShareProfile from "../pages/private/Share/ModalShareProfile";

type UserProps = {
  firstName?: string;
  lastName?: string;
  startupId?: number | string;
  profileImage?: string;
};

const SideBar: FC<UserProps> = ({
  firstName,
  lastName,
  startupId,
  profileImage,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { dispatch, isOnboarding, isSharingDisabled } = useContext(AppContext);

  const [isOpen, setIsOpen] = React.useState(false);

  const modalIsOpen = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  const handlerLogout = (e: React.SyntheticEvent) => {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure?",
      text: "You want to logout?",
      icon: "warning",
      confirmButtonText: "Yes",
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch({ type: "CLOSE_AUTH" });
        navigate("/login", { replace: true });
        localStorage.clear();
      }
    });
  };

  return (
    <nav className="sidebar__box">
      <List
        component="nav"
        subheader={
          <ListSubheader component="div">
            <img
              src={PumpJuiceLogo}
              className="sidebar__logo_img"
              alt="PumpJuiceLogo"
              onClick={() => navigate("/")}
            />
          </ListSubheader>
        }
      >
        <ListItemButton>
          <ListItemIcon>
            <Avatar
              sx={{
                width: 48,
                height: 48,
                backgroundColor: "rgba(105, 226, 142, 0.6)",
              }}
              alt={firstName}
              src={profileImage}
            />
          </ListItemIcon>
          <ListItemText primary={firstName ? firstName : "-"} />
        </ListItemButton>

        <hr style={{ width: "90%" }} />
        <ButtonNavActive
          navRoute={"/dashboard"}
          title={"Dashboard"}
          isActive={location.pathname.includes("/dashboard")}
        />
        <ButtonNavActive
          navRoute={
            isOnboarding ? "/startup-profile" : "/startup-profile/UserProfile"
          }
          title={"Startup Page"}
          isActive={location.pathname.includes("/startup-profile")}
        />
        <ButtonNavActive
          navRoute={"/profile"}
          title={"Profile"}
          isActive={location.pathname.includes("/profile")}
        />
        {!isOnboarding && (
          <ButtonNavActive
            navRoute={"/fundraising"}
            title={"Capital"}
            isActive={location.pathname.includes("/fundraising")}
          />
        )}
      </List>

      <List>
        <ListItemButton
          onClick={!isSharingDisabled() ? modalIsOpen : undefined}
          disabled={isSharingDisabled()}
          sx={{
            ":hover": {
              backgroundColor: !isSharingDisabled()
                ? "rgba(231, 248, 235, 1)"
                : "initial",
              borderRadius: "6px",
            },
            cursor: isSharingDisabled() ? "not-allowed" : "pointer",
            opacity: isSharingDisabled() ? 0.5 : 1,
          }}
        >
          <ListItemIcon>
            <Avatar
              sx={{
                bgcolor: "rgba(231, 248, 235, 1)",
                borderRadius: "10px",
              }}
            >
              <IoShareSocialOutline color="rgba(4, 191, 123, 1)" />
            </Avatar>
          </ListItemIcon>
          <ListItemText
            sx={{
              ":hover": {
                color: !isSharingDisabled()
                  ? "rgba(4, 191, 123, 1)"
                  : "initial",
              },
            }}
            primary={"Share"}
          />
        </ListItemButton>
        <ButtonNavActive
          navRoute={"/settings"}
          title={"Settings"}
          isActive={location.pathname.includes("/settings")}
        />
        <ListItemButton
          onClick={(e) => handlerLogout(e)}
          sx={{
            ":hover": {
              backgroundColor: "rgba(231, 248, 235, 1)",
              borderRadius: "6px",
            },
          }}
        >
          <ListItemIcon>
            <Avatar
              sx={{ bgcolor: "rgba(231, 248, 235, 1)", borderRadius: "10px" }}
            >
              <IoIosPower color="rgba(4, 191, 123, 1)" />
            </Avatar>
          </ListItemIcon>
          <ListItemText
            sx={{
              ":hover": {
                color: "rgba(4, 191, 123, 1)",
              },
            }}
            primary={"Logout"}
          />
        </ListItemButton>
      </List>
      <ModalShareProfile
        modalIsOpen={isOpen}
        closeModal={closeModal}
        startupId={startupId}
      />
    </nav>
  );
};

export default SideBar;
