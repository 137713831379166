import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Grid,
} from "@mui/material";
import React, { useState } from "react";
import { FaRegFilePdf, FaRegStar } from "react-icons/fa";
import { HiMiniDevicePhoneMobile } from "react-icons/hi2";
import { IoLogoAndroid } from "react-icons/io";
import { LuClock4 } from "react-icons/lu";
import { PiPlugsConnected } from "react-icons/pi";
import BarChart from "./Barchart";
import HorizontalLineChart from "./HorizontalLineChart";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import OverallFeedback from "../OverallFeedback/OverallFeedback";
import { formatDistanceToNow } from "date-fns";
import { ReviewsDashBoard } from "../../../../../types/dashboard";
import { getTimeAgo } from "../../../../../helpers/Utils";

const VisitorCard = ({ visitorData }: { visitorData: ReviewsDashBoard }) => {
  const [modalIsOpen, setIsOpen] = useState<boolean>(false);
  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  const getInitials = (name: string): string => {
    const [firstName, lastName] = name.split(" ");
    const initials = firstName.charAt(0) + (lastName ? lastName.charAt(0) : "");
    return initials.toUpperCase();
  };

  return (
    <>
      <Accordion
        key={1}
        sx={{
          "&.MuiAccordion-root:": {
            borderRadius: "25px",
          },
          width: "100%",
          boxShadow:
            "0px -2px 80px 0px rgba(0, 0, 0, 0.07), 0px -0.836px 33.422px 0px rgba(0, 0, 0, 0.05), 0px -0.447px 17.869px 0px rgba(0, 0, 0, 0.04), 0px -0.25px 10.017px 0px rgba(0, 0, 0, 0.04), 0px -0.133px 5.32px 0px rgba(0, 0, 0, 0.03), 0px -0.055px 2.214px 0px rgba(0, 0, 0, 0.02)",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1d-content"
          id="panel1d-header"
        >
          <Grid
            justifyContent="space-between"
            container
            direction="row"
            alignItems="center"
          >
            <Grid
              item
              xs={4}
              display={"flex"}
              flexDirection={"row"}
              alignItems="center"
              justifyContent={"space-between"}
            >
              <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                <Avatar
                  alt={visitorData.email}
                  src="/static/images/avatar/1.jpg"
                  sx={{ bgcolor: "rgba(105, 226, 142, 0.6)" }}
                >
                  {getInitials(visitorData.email)}
                </Avatar>
                <p
                  style={{
                    margin: "0px 0px 0px 10px",
                    fontSize: "12px",
                  }}
                >
                  {visitorData.email}
                </p>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box>
                <p
                  style={{
                    margin: "0px 0px 0px 10px",
                    fontSize: "12px",
                    textAlign: "center",
                  }}
                >
                  {visitorData.totalVisits}
                </p>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box
                display={"flex"}
                flexDirection={"row"}
                alignItems={"flex-end"}
                justifyContent={"flex-end"}
              >
                <p
                  style={{
                    margin: "0px 5px 0px 0px",
                    fontSize: "12px",
                  }}
                >
                  {`${getTimeAgo(visitorData.lastVisit)}`}
                </p>
              </Box>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container direction="row" p={1}>
            <Grid item xs={6}>
              <Box display={"flex"} flexDirection={"row"}>
                {/* <p style={{ marginRight: "10px" }}>
              San Diego, California, United States
            </p>{" "} */}
                <HiMiniDevicePhoneMobile color="#04BF7B" size={22} />
                <IoLogoAndroid color="#04BF7B" size={22} />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"flex-end"}
              >
                <button className="button__status color__inactive">
                  <PiPlugsConnected />{" "}
                  {!!visitorData?.latestConnectButton?.connectYes
                    ? "Connected"
                    : "Disconected"}
                </button>
              </Box>
            </Grid>
            <Grid item mb={2} md={12}>
              <hr style={{ margin: "0", color: "#04BF7B" }} />
            </Grid>
            <Grid display={"flex"} flexDirection={"row"} item xs={12}>
              <Grid container spacing={4}>
                <Grid display={"flex"} direction={"row"} item xs={3}>
                  <LuClock4 style={{ margin: "4px", color: "#04BF7B" }} />
                  <p>{visitorData.totalTimeSpentOnPages} time spent</p>
                </Grid>
                <Grid display={"flex"} direction={"row"} item xs={3}>
                  <LuClock4 style={{ margin: "4px", color: "#04BF7B" }} />
                  <p>- viewed</p>
                </Grid>
                <Grid display={"flex"} direction={"row"} item xs={3}>
                  <FaRegFilePdf style={{ margin: "4px", color: "#04BF7B" }} />
                  <p style={{ fontSize: "16px" }}>
                    {visitorData?.latestDeckDownload?.deckDownloadYes
                      ? "Downloaded"
                      : "Download"}
                  </p>
                </Grid>
                <Grid
                  display={"flex"}
                  direction={"row"}
                  item
                  xs={3}
                  sx={{ alignItems: "baseline" }}
                >
                  <FaRegStar style={{ margin: "4px", color: "#04BF7B" }} />
                  <p style={{ marginRight: "4px" }}>
                    {visitorData.review.overalRating}
                  </p>

                  {visitorData.review.details && ( // Will be showing View Details Button when the review is not Empty.
                    <button
                      style={{
                        color: "#04BF7B",
                        border: "none",
                        borderRadius: "5px",
                        margin: "none",
                        height: "30px",
                        background: "none",
                        fontWeight: "bold",
                        fontSize: "12px",
                        padding: "none",
                      }}
                      onClick={openModal}
                    >
                      View Details
                    </button>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12}>
              <hr style={{ margin: "0", color: "#04BF7B" }} />
            </Grid>
          </Grid>
          <Grid container direction="row" p={1}>
            <Grid item xs={12}>
              <p>Pitch Deck | Total time spent per slide</p>
            </Grid>
          </Grid>
          <Grid container direction="row" p={1}>
            <Grid item xs={12}>
              <BarChart dataChart={visitorData.latestPitchDeck} />
            </Grid>
          </Grid>
          {/* <Grid container
       direction="row" p={1}>
          <Grid item xs={6}>
          <p>Video Playback | Total viewing time</p>
          </Grid>
          <Grid
          display={"flex"}
          item xs={6}>
          <p style={{color: '#787878'}}>Video Total Duration: {visitorData.latestVideo.totalTimeSpentOnVideo} seconds</p>
          </Grid>
      </Grid> */}
          <Grid container>
            <Grid item xs={12}>
              <HorizontalLineChart dataChart={visitorData.latestVideo} />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <OverallFeedback
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        // reviews={[visitorData.review]}
        reviews={visitorData.review.details ? [visitorData.review] : []}
      />
    </>
  );
};

export default VisitorCard;
