import React, { FC } from "react";
import { Box, Grid } from "@mui/material";
import { LiaTimesSolid } from "react-icons/lia";
import * as Yup from "yup";
import { Field, ErrorMessage, getIn, Formik, Form } from "formik";
import Modal from "react-modal";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

type inputProps = {
  arrayHelpers: any;
  index: number;
  modalIsOpen: boolean;
  closeModal: () => void;
};

type newExperienceFormProps = {
  title: string;
  company: string;
  startDate: string;
  endDate: string;
};

const ModalAddExperience: FC<inputProps> = ({
  arrayHelpers,
  index,
  modalIsOpen,
  closeModal,
}) => {
  const initialValues: newExperienceFormProps = {
    title: "",
    company: "",
    startDate: "",
    endDate: "",
  };

  const handleSubmit = async (values: any) => {
    arrayHelpers.push({
      title: values.title,
      company: values.company,
      startDate: values.startDate,
      endDate: values.endDate,
    });
    closeModal();
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    company: Yup.string().required("Company is required"),
    startDate: Yup.string()
      .required("Start date is required")
      .matches(
        /^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?[0-9]{2})*$/,
        "Date must be in MM/DD/YYYY format"
      )
      .test(
        "startDate",
        "Start Date cannot be in the future",
        function (value) {
          return moment(value, "MM/DD/YYYY").toDate() <= moment().toDate();
        }
      ),
    endDate: Yup.string()
      .nullable()
      .when("startDate", (startDate, schema) =>
        startDate
          ? schema
              .nullable()
              .test(
                "endDate",
                "End Date must be after Start Date",
                function (value) {
                  return (
                    !value ||
                    moment(value, "MM/DD/YYYY").toDate() >=
                      moment(startDate, "MM/DD/YYYY").toDate()
                  );
                }
              )
          : schema
      )
      .matches(
        /^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?[0-9]{2})*$/,
        "Date must be in MM/DD/YYYY format"
      )
      .matches(
        /^(0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?\d\d$/,
        "Date must be in MM/DD/YYYY format"
      )
      .test("endDate", "End Date cannot be in the future", function (value) {
        return (
          !value || moment(value, "MM/DD/YYYY").toDate() <= moment().toDate()
        );
      }),
  });

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      className="Modal"
      overlayClassName="Overlay"
      contentLabel="Choice Modal"
      ariaHideApp={false}
    >
      {" "}
      <Grid
        container
        display="flex"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        p={"1rem"}
        color={"white"}
        bgcolor={"#012626"}
      >
        <Box>
          <p className="buildProfile__modal_paragraph">Add your experience</p>
        </Box>
        <Box>
          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              padding: "10px",
            }}
            onClick={closeModal}
          >
            <LiaTimesSolid size={32} color="#04BF7B" />
          </button>
        </Box>
      </Grid>
      <Grid
        p={4}
        container
        display="flex"
        direction="column"
        alignItems="stretch"
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ values, touched, errors, setFieldValue }) => (
            <Form>
              <Grid item xs={12} mb={4}>
                <Field
                  type="text"
                  placeholder="Title"
                  name="title"
                  className={`form-control mb-3 ${
                    touched.title
                      ? errors.title
                        ? "is-invalid"
                        : "is-valid"
                      : ""
                  }`}
                  style={{
                    height: "53px",
                    borderRadius: "10px",
                  }}
                />
                <ErrorMessage
                  name="title"
                  component="p"
                  className="requiredField"
                />
              </Grid>
              <Grid item xs={12} mb={4}>
                <Field
                  type="text"
                  className={`form-control mb-3`}
                  name={`company`}
                  placeholder="Company"
                  style={{
                    height: "53px",
                    borderRadius: "10px",
                  }}
                />
                <ErrorMessage
                  name={`company`}
                  component="p"
                  className="requiredField"
                />
              </Grid>
              <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
              >
                <Grid item xs={5} mb={2}>
                  <DatePicker
                    className="form-control customField mb-3"
                    selected={
                      values.startDate
                        ? moment(values.startDate, "MM/DD/YYYY").toDate()
                        : null
                    }
                    onChange={(date) =>
                      setFieldValue(
                        "startDate",
                        date ? moment(date).format("MM/DD/YYYY") : null
                      )
                    }
                    wrapperClassName="form-control customField mb-3"
                    dateFormat="MM/dd/yyyy"
                    isClearable={true}
                    placeholderText="Start Date"
                  />

                  <ErrorMessage
                    name={`startDate`}
                    component="p"
                    className="requiredField"
                  />
                </Grid>

                <Grid item xs={5} mb={2}>
                  <DatePicker
                    className="form-control customField mb-3"
                    selected={
                      values.endDate
                        ? moment(values.endDate, "MM/DD/YYYY").toDate()
                        : null
                    }
                    onChange={(date) =>
                      setFieldValue(
                        "endDate",
                        date ? moment(date).format("MM/DD/YYYY") : null
                      )
                    }
                    wrapperClassName="form-control customField mb-3"
                    dateFormat="MM/dd/yyyy"
                    isClearable={true}
                    placeholderText="End Date"
                  />

                  <ErrorMessage
                    name={`endDate`}
                    component="p"
                    className="requiredField"
                  />
                </Grid>
              </Box>
              <Box mt={2} textAlign={"center"}>
                <button type="submit" className="button__primary color__active">
                  Save Experience
                </button>
              </Box>
            </Form>
          )}
        </Formik>
      </Grid>
    </Modal>
  );
};

export default ModalAddExperience;
