import { FC, useContext, useState } from "react";
import { Box } from "@mui/material";
import { BiSolidPencil } from "react-icons/bi";
import StartupDescriptionInnerModal from "../ModalChoice/Modals/StartupDescriptionInnerModal";
import { AppContext } from "../../../../../context";
import MainCard from "../../../../../components/components/MainCard";
import { Paragraph } from "../../../../../styled/Paragraph";

interface CardChoosingProps {
  title: string | null | undefined;
  description: string | null | undefined;
  userId: string | number | null | undefined;
  isReadOnly?: boolean;
}

const CardStartupDescription: FC<CardChoosingProps> = ({
  title,
  description,
  userId,
  isReadOnly = false,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const { getStartupProfile } = useContext(AppContext);

  const openModal = () => {
    if (!isReadOnly) {
      setModalIsOpen(true);
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
    getStartupProfile();
  };

  return (
    <Box
    // sx={{
    //   margin: "70px 0px",
    //   display: "flex",
    //   flexDirection: "column",
    //   gap: 4,
    // }}
    >
      <MainCard title={title || "Startup Description"}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            // mb: 2,
          }}
        >
          {" "}
          <Paragraph fontSize={18} fontWeight="400">
            {description}
          </Paragraph>
          {!isReadOnly && (
            <button
              onClick={openModal}
              className="button__edit color__active"
              style={{
                backgroundColor: "#04BF7B",
                borderRadius: "8px",
                padding: "8px",
                cursor: "pointer",
                transition: "background-color 0.3s ease",
              }}
            >
              <BiSolidPencil size={22} />
            </button>
          )}
        </Box>
      </MainCard>
      <StartupDescriptionInnerModal
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        description={description}
        userId={userId}
      />
    </Box>
  );
};

export default CardStartupDescription;
