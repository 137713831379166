import React, { ReactNode, useEffect, useState, useContext } from "react";
import SideBar from "./Sidebar";
import NavBar from "./NavBar";
import { Container, Grid } from "@mui/material";
import { getProfileData } from "../helpers/Api";
import { AppContext } from "../context/index";

// Define props type for AppProvider
interface AppProviderProps {
  children: ReactNode;
}

type UserProps = {
  id: string;
  firstName: string;
  lastName: string;
  pictureUrl: string;
};

// const MainLayout = ({ children }: AppProviderProps): JSX.Element => {
const MainLayout: React.FC<AppProviderProps> = ({ children }) => {
  const [data, setData] = useState<UserProps>({} as UserProps);
  const { isChangeName, setIsChangeName, setUserData } = useContext(AppContext);

  useEffect(() => {
    getProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChangeName]);

  const getProfile = async () => {
    try {
      const response = await getProfileData();
      if (response) {
        setData(response);
        setUserData(response.firstName)
      }
    } catch (error) {
      throw error;
    } finally {
      setIsChangeName(false);
    }
  };

  const firstName = data?.firstName;
  const lastName = data?.lastName;
  const startupId = data?.id;
  const profileImage = data?.pictureUrl;

  return (
    <>
      <Container maxWidth={false} disableGutters>
        <div className="mainLayout__grid_LargeDevice">
          <Grid container direction="row">
            <Grid md={3} sm={12} item>
              <SideBar
                firstName={firstName}
                lastName={lastName}
                startupId={startupId}
                profileImage={profileImage}
              />
            </Grid>
            <Grid md={8} sm={12} item>
              {children}
            </Grid>
          </Grid>
        </div>

        <div className="mainLayout__grid_SmallDevice">
          <Grid
            container
            direction="column"
            className="mainLayout__grid_SmallDevice"
          >
            <Grid sm={12} item>
              <NavBar />
            </Grid>
            <Grid
              sm={12}
              item
              sx={{ maxWidth: "100%!important", padding: "0px 25px" }}
            >
              {children}
            </Grid>
          </Grid>
        </div>
      </Container>
    </>
  );
};

export default MainLayout;
