import React, { FC, useContext, useState } from "react";
import {
  Grid,
  Box,
  Autocomplete,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Swal from "sweetalert2";
// import Select from "react-select";
import { IoIosArrowRoundForward } from "react-icons/io";
import { IoIosArrowRoundBack } from "react-icons/io";
import circleBg from "../../../../assets/circle_bg.svg";
//Selectors Collection
import { IndustryCollection } from "../../../../helpers/IndustriesSelector";
import { EmployeeCollection } from "../../../../helpers/EmployeeSelector";
import { StageCollection } from "../../../../helpers/StageSelector";
//API
import { createStartupProfile } from "../../../../helpers/Api";
import { AppContext } from "../../../../context";
import useDebounce from "../../../../hooks/useDebounce";
import { getLocations } from "../../../../api/gralInformation";

const StartupProfile: FC = () => {
  type StartupProfileProps = {
    name: string;
    description: string | null;
    location: string;
    industry: [{ label: string; value: string }];
    employeeCount: [{ label: string; value: string }];
    stage: [{ label: string; value: string }];
    websiteUrl: string;
  };

  type OptionItem = {
    value: string;
    label: string;
  };

  const navigate = useNavigate();
  const [industry, setIndustry] = useState<string>(IndustryCollection[0].value);
  const [employees, setEmployees] = useState<string>(
    EmployeeCollection[0].value
  );
  const [stage, setStage] = useState<string>(StageCollection[0].value);
  const [countries, setCountries] = useState<string[]>([]);
  const [countrySelected, setCountrySelected] = useState<string>("");
  const [startupUrl, setStartupUrl] = useState<string>("");
  const [startupName, setStartupName] = useState<string>("");
  const { setIsOnboarding } = useContext(AppContext);

  const initialValues: StartupProfileProps = {
    name: "",
    description: null,
    location: "",
    industry: [{ label: "", value: "" }],
    employeeCount: [{ label: "", value: "" }],
    stage: [{ label: "", value: "" }],
    websiteUrl: "",
  };

  const handleCreateStartup = async (values: StartupProfileProps) => {
    try {
      const response = await createStartupProfile({
        name: startupName,
        description: null,
        location: countrySelected,
        industry: industry,
        employeeCount: employees,
        stage: stage,
        websiteUrl: startupUrl,
      });
      if (response) {
        setIsOnboarding(false);
        localStorage.setItem("isOnboarding", "false");
        Swal.fire(
          "Success",
          "Amazing you've created the startup profile",
          "success"
        );
        navigate("/startup-profile/UserProfile");
      }
    } catch (error) {
      Swal.fire(
        "Oops",
        `Looks like there is something wrong <br />
          `,
        "error"
      );
    }
  };

  const handleIndustry = (option: string) => {
    return setIndustry(option);
  };

  const handleInputEmployees = (option: string) => {
    return setEmployees(option);
  };

  const handleInputStage = (option: string) => {
    return setStage(option);
  };

  const handleSearchLocation = async (value: string) => {
    const apiResponse = await getLocations(value);

    if (apiResponse) {
      setCountries(apiResponse.map((el: { location: string }) => el.location));
    }
  };

  const { debouncedCallback } = useDebounce((newInputValue: string) => {
    if (newInputValue) {
      handleSearchLocation(newInputValue);
    }
  }, 500);

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={(values: StartupProfileProps) => {
          handleCreateStartup(values);
        }}
      >
        {(props) => (
          <>
            <Form>
              <Grid
                container
                className="startupProfile__box"
                direction="column"
                justifyContent="space-evenly"
                alignItems="flex-start"
                sx={{ width: "auto" }}
              >
                <Grid item md={12}>
                  <p>
                    We need essential information from your startup to get ready
                  </p>
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <p
                      style={{
                        fontWeight: "400",
                        fontSize: "40px",
                      }}
                    >
                      Tell us a bit about your startup
                    </p>
                  </Box>
                </Grid>

                <Grid
                  item
                  md={12}
                  pt={4}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      maxWidth: { sm: "100%", md: "660px" },
                      "@media (max-width:950px)": {
                        maxWidth: "100%",
                      },
                    }}
                  >
                    <label htmlFor="name">Startup name</label>
                    <TextField
                      name="name"
                      variant="outlined"
                      fullWidth
                      placeholder="e.g. Unicorn Inc."
                      onChange={(e) => setStartupName(e.target.value)}
                      error={Boolean(props.errors.name && props.touched.name)}
                      helperText={<ErrorMessage name="name" />}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px",
                        },
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      maxWidth: { sm: "100%", md: "660px" },
                      "@media (max-width:950px)": {
                        maxWidth: "100%",
                      },
                    }}
                  >
                    <label htmlFor="location">Location</label>
                    <Autocomplete
                      id="location"
                      options={countries}
                      autoHighlight
                      getOptionLabel={(option) => option}
                      onChange={(_event: any, newValue: string | null) =>
                        newValue && setCountrySelected(newValue)
                      }
                      onInputChange={(_event, newInputValue) => {
                        debouncedCallback(newInputValue);
                      }}
                      sx={{
                        borderRadius: "10px",
                      }}
                      renderOption={(props, option) => {
                        return (
                          <Box
                            component="li"
                            {...props}
                            sx={{
                              borderRadius: "10px",
                            }}
                          >
                            {option}
                          </Box>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="e.g. San Francisco, CA"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password", // disable autocomplete and autofill
                          }}
                          sx={{
                            "& .MuiInputBase-input": {
                              margin: 0,
                              padding: 0,
                            },
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "10px",
                            },
                          }}
                        />
                      )}
                    />
                    <ErrorMessage
                      name="location"
                      component="p"
                      className="requiredField"
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      maxWidth: { sm: "100%", md: "660px" },
                      "@media (max-width:950px)": {
                        maxWidth: "100%",
                      },
                    }}
                  >
                    <label>Industry</label>
                    <Select
                      value={industry}
                      onChange={(e) => handleIndustry(e.target.value)}
                      sx={{
                        borderRadius: "10px",
                      }}
                    >
                      {IndustryCollection.map((el: OptionItem) => (
                        <MenuItem key={el.value} value={el.value}>
                          {el.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      maxWidth: { sm: "100%", md: "660px" },
                      "@media (max-width:950px)": {
                        maxWidth: "100%",
                      },
                    }}
                  >
                    <label>Employees number</label>
                    <Select
                      value={employees}
                      onChange={(e) => handleInputEmployees(e.target.value)}
                      sx={{
                        borderRadius: "10px",
                      }}
                    >
                      {EmployeeCollection.map((el: OptionItem) => (
                        <MenuItem key={el.value} value={el.value}>
                          {el.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      maxWidth: { sm: "100%", md: "660px" },
                      "@media (max-width:950px)": {
                        maxWidth: "100%",
                      },
                    }}
                  >
                    <label>Stage</label>
                    <Select
                      value={stage}
                      onChange={(e) => handleInputStage(e.target.value)}
                      sx={{
                        borderRadius: "10px",
                      }}
                    >
                      {StageCollection.map((el: OptionItem) => (
                        <MenuItem key={el.value} value={el.value}>
                          {el.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      maxWidth: { sm: "100%", md: "660px" },
                      "@media (max-width:950px)": {
                        maxWidth: "100%",
                      },
                    }}
                  >
                    <label>Website</label>
                    <TextField
                      name="websiteUrl"
                      variant="outlined"
                      fullWidth
                      placeholder="e.g. www.unicorn.com"
                      onChange={(e) => setStartupUrl(e.target.value)}
                      error={Boolean(
                        props.errors.websiteUrl && props.touched.websiteUrl
                      )}
                      helperText={<ErrorMessage name="websiteUrl" />}
                      sx={{
                        borderRadius: "10px",
                      }}
                    />
                  </Box>
                  {/* <Grid
                    item
                    display={"flex"}
                    justifyContent="flex-end"
                    md={6}
                  ></Grid> */}
                </Grid>
              </Grid>
              <Grid
                container
                display={"flex"}
                direction={"row"}
                justifyContent={"space-between"}
                pt={12}
                pb={4}
              >
                <Grid item md={6}>
                  <button
                    type="button"
                    onClick={() => navigate("/startup-profile")}
                    className="button__navigation color__inactive"
                  >
                    <IoIosArrowRoundBack size={26} /> Back
                  </button>
                </Grid>
                <Grid item display={"flex"} justifyContent="flex-end" md={6}>
                  <button
                    type="submit"
                    className="button__navigation color__active"
                  >
                    Next <IoIosArrowRoundForward size={26} />
                  </button>
                </Grid>
                <div className="startupProfile__circleBg">
                  <img src={circleBg} alt="dots" className="dotsBg" />
                </div>
              </Grid>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};

export default StartupProfile;
