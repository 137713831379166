import React, { FC, useState } from "react";
import { Box, Grid, Container } from "@mui/material";
import Chip from "@mui/material/Chip";
import { LiaTimesSolid } from "react-icons/lia";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { NumericFormat } from "react-number-format";
import Swal from "sweetalert2";
import ButtonGroup from "@mui/material/ButtonGroup";
import Modal from "react-modal";
//Custom made components
import CustomChip from "../../../../../../components/components/CustomChip";
import CustomButtonGroup from "../../../../../../components/components/CustomButtonGroup";
//API calls
import { createBusinessTraction } from "../../../../../../helpers/Api";

type IdProp = {
  modalIsOpen: boolean;
  userId: string;
  closeModal: () => void;
};

type clientsCatcher = {
  target: { value: string };
};

interface BusinessTractionProps {
  mrr: number | null | undefined;
}

type CustomersProps = {
  name: string;
};

const BusinessTractionInnerModal: FC<IdProp> = ({
  modalIsOpen,
  closeModal,
  userId,
}) => {
  const initialValues = {
    mrr: 0,
    topCustomers: [],
  };

  const startupId = userId.toString();
  const [businessModel, setBusinessModel] = useState("");
  const [mainResource, setMainResource] = useState("");

  const [addCustomer, setAddCustomer] = useState<string>("");
  const [topCustomers, setTopCustomers] = useState<CustomersProps[]>([]);

  const addCustomerHandler = (customer: string) => {
    const clientsCatcher = [...topCustomers, { name: customer }];
    setTopCustomers(clientsCatcher);
    setAddCustomer("");
  };

  const handleCreateTraction = async (values: BusinessTractionProps) => {
    try {
      const response = await createBusinessTraction(
        {
          businessModel: businessModel,
          businessRevenueSources: mainResource,
          mrr: values.mrr,
          topCustomers: topCustomers as any,
        },
        startupId
      );
      if (response) {
        Swal.fire(
          "Success",
          "Amazing you've added your Business Traction",
          "success"
        );
        closeModal();
      }
    } catch (error) {
      Swal.fire(
        "Oops",
        `Looks like there is something wrong <br />
          `,
        "error"
      );
    }
  };

  const handleDelete = (index: number) => {
    const updatedCustomers = topCustomers.filter((_, i) => i !== index);
    setTopCustomers(updatedCustomers);
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      className="Modal"
      overlayClassName="Overlay"
      contentLabel="Choice Modal"
      ariaHideApp={false}
    >
      <Grid
        container
        display="flex"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        p={"1rem"}
        color={"white"}
        bgcolor={"#012626"}
      >
        <Box>
          <p className="buildProfile__modal_paragraph">
            Let’s talk about your business
          </p>
        </Box>
        <Box>
          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              padding: "10px",
            }}
            onClick={closeModal}
          >
            <LiaTimesSolid size={32} color="#04BF7B" />
          </button>
        </Box>
      </Grid>
      <Grid
        p={4}
        container
        display="flex"
        direction="column"
        alignItems="stretch"
      >
        <Box p={1}>
          <Box>
            <label htmlFor="name">
              Which one is your startup’s business model?
            </label>
          </Box>
          <Grid
            container
            display={"flex"}
            direction={"row"}
            justifyContent={"flex-start"}
            mt={2}
          >
            <CustomChip
              label="Product Sales"
              modelType="product_sales"
              modelChoosen={businessModel}
              optionSelected={setBusinessModel}
            />
            <CustomChip
              label="Platform"
              modelType="platform"
              modelChoosen={businessModel}
              optionSelected={setBusinessModel}
            />
            <CustomChip
              label="Service Based"
              modelType="service_based"
              modelChoosen={businessModel}
              optionSelected={setBusinessModel}
            />
            <CustomChip
              label="Marketplace"
              modelType="marketplace"
              modelChoosen={businessModel}
              optionSelected={setBusinessModel}
            />
            <CustomChip
              label="Subscription"
              modelType="subcription"
              modelChoosen={businessModel}
              optionSelected={setBusinessModel}
            />
            <CustomChip
              label="SaaS"
              modelType="saas"
              modelChoosen={businessModel}
              optionSelected={setBusinessModel}
            />
            <CustomChip
              label="Freemium"
              modelType="fremium"
              modelChoosen={businessModel}
              optionSelected={setBusinessModel}
            />
            <CustomChip
              label="Other"
              modelType="other"
              modelChoosen={businessModel}
              optionSelected={setBusinessModel}
            />
          </Grid>
          <Grid item xs={12} display={"flex"} direction={"column"} mb={1}>
            <Box mt={2} mb={1}>
              <label htmlFor="name">
                What’s your startup’s main revenue source?
              </label>
            </Box>
            <ButtonGroup aria-label="Basic button group">
              <CustomButtonGroup
                label="B2B"
                modelType="b2b"
                modelChoosen={mainResource}
                optionSelected={setMainResource}
              />
              <CustomButtonGroup
                label="B2C"
                modelType="b2c"
                modelChoosen={mainResource}
                optionSelected={setMainResource}
              />
              <CustomButtonGroup
                label="B2B2C"
                modelType="b2b2c"
                modelChoosen={mainResource}
                optionSelected={setMainResource}
              />
            </ButtonGroup>
          </Grid>
          <Formik
            initialValues={initialValues}
            onSubmit={(values: BusinessTractionProps) => {
              handleCreateTraction(values);
            }}
          >
            {(props) => (
              <Form>
                <Grid item xs={12} display={"flex"} direction={"column"} mb={1}>
                  <Box mt={2} mb={1}>
                    <label htmlFor="mrr">Enter your startup’s MRR:</label>
                  </Box>
                  <NumericFormat
                    type="text"
                    prefix={"$"}
                    suffix={" USD"}
                    decimalSeparator={"."}
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    placeholder="$0.0"
                    value={0}
                    onValueChange={(value) =>
                      props.setFieldValue("mrr", value.floatValue)
                    }
                    className="form-control"
                    style={{
                      textAlign: "left",
                      height: "51px",
                      borderRadius: "10px",
                      border: "1px solid #d1d1d1",
                      marginTop: "10px",
                      marginBottom: "16px",
                      alignContent: "center",
                    }}
                  />
                </Grid>

                <Box mt={1}>
                  <label htmlFor="topCustomers">
                    Who are your top customers?
                  </label>
                </Box>
                <Grid item display={"flex"} direction={"row"} xs={12}>
                  <Field
                    type="text"
                    onChange={(e: clientsCatcher) =>
                      setAddCustomer(e.target.value)
                    }
                    name="topCustomers"
                    placeholder="e.g. Walmart."
                    style={{ width: "100%", height: "51px" }}
                    className="form-control mt-3"
                    value={addCustomer}
                  />
                  <ErrorMessage
                    name="topCustomers"
                    component="p"
                    className="requiredField"
                  />
                  <Box mt={2}>
                    <button
                      type="button"
                      onClick={() => addCustomerHandler(addCustomer)}
                      style={{
                        width: "100%",
                        height: "51px",
                        padding: "12px",
                        backgroundColor: "#F8F8F8",
                        color: "#343434",
                        border: "1px solid #d1d1d1",
                        borderRadius: "10px",
                        alignContent: "center",
                      }}
                    >
                      Add
                    </button>
                  </Box>
                </Grid>
                {topCustomers.length > 0 && (
                  <Grid item md={12}>
                    <Box mt={2}>
                      <p>Customers list:</p>
                    </Box>
                    <Grid item md={12}>
                      <Container maxWidth="lg">
                        {topCustomers.map((value, index) => (
                          <Chip
                            key={index}
                            sx={{ margin: "10px" }}
                            label={value?.name}
                            onDelete={() => handleDelete(index)}
                          />
                        ))}
                      </Container>
                    </Grid>
                  </Grid>
                )}
                <Box mt={6} textAlign={"center"}>
                  <button
                    type="submit"
                    className="button__primary color__active"
                  >
                    Save
                  </button>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Grid>
    </Modal>
  );
};

export default BusinessTractionInnerModal;
