import * as React from "react";
import { Box, Grid, Pagination, Typography } from "@mui/material";
import VisitorCard from "./VisitorCard";
import { useContext } from "react";
import { ReviewsDashBoard } from "../../../../../types/dashboard";
import { AppContext } from "../../../../../context";
import { IoLockOpenOutline } from "react-icons/io5";
import { Paragraph } from "../../../../../styled/Paragraph";
import { useState, useEffect, useMemo } from "react";
import { CustomPagination } from "../../../../../styled/MainCard";

const ITEMS_PER_PAGE = 10; // Adjust this number as needed

// ... keep the fakeData array as is ...
// const fakeData: ReviewsDashBoard[] = [
//   {
//     email: "john@example.com",
//     review: {
//       email: "john@example.com",
//       overalRating: 4,
//       teamValue: 3,
//       problemValue: 4,
//       solutionValue: 5,
//       gtmstrategyValue: 4,
//       marketoppValue: 3,
//       details:
//         "Great product overall, but market opportunity could be better defined.",
//     },
//     totalVisits: 15,
//     totalTimeSpentOnPages: 3600,
//     userBrowser: "Chrome",
//     userDeviceType: "Desktop",
//     userOs: "Windows",
//     latestPitchDeck: {},
//     latestVideo: {},
//     latestDeckDownload: { deckDownloadYes: true },
//     latestPassButton: { passYes: false },
//     latestConnectButton: { connectYes: true },
//     totalShares: 5,
//     lastVisit: Date.now() - 1000000,
//   },
//   {
//     email: "alice@example.com",
//     review: {
//       email: "alice@example.com",
//       overalRating: 5,
//       teamValue: 4,
//       problemValue: 5,
//       solutionValue: 5,
//       gtmstrategyValue: 5,
//       marketoppValue: 4,
//       details: "Impressive solution with a strong team.",
//     },
//     totalVisits: 20,
//     totalTimeSpentOnPages: 4800,
//     userBrowser: "Firefox",
//     userDeviceType: "Mobile",
//     userOs: "iOS",
//     latestPitchDeck: {},
//     latestVideo: {},
//     latestDeckDownload: { deckDownloadYes: true },
//     latestPassButton: { passYes: false },
//     latestConnectButton: { connectYes: true },
//     totalShares: 8,
//     lastVisit: Date.now() - 500000,
//   },
//   {
//     email: "bob@example.com",
//     review: {
//       email: "bob@example.com",
//       overalRating: 3,
//       teamValue: 3,
//       problemValue: 4,
//       solutionValue: 3,
//       gtmstrategyValue: 2,
//       marketoppValue: 3,
//       details: "Interesting concept, but needs work on go-to-market strategy.",
//     },
//     totalVisits: 8,
//     totalTimeSpentOnPages: 1800,
//     userBrowser: "Safari",
//     userDeviceType: "Tablet",
//     userOs: "iPadOS",
//     latestPitchDeck: {},
//     latestVideo: {},
//     latestDeckDownload: { deckDownloadYes: false },
//     latestPassButton: { passYes: true },
//     latestConnectButton: { connectYes: false },
//     totalShares: 1,
//     lastVisit: Date.now() - 2000000,
//   },
//   {
//     email: "carol@example.com",
//     review: {
//       email: "carol@example.com",
//       overalRating: 4,
//       teamValue: 5,
//       problemValue: 4,
//       solutionValue: 4,
//       gtmstrategyValue: 3,
//       marketoppValue: 4,
//       details:
//         "Strong team addressing a real problem. GTM strategy needs refinement.",
//     },
//     totalVisits: 12,
//     totalTimeSpentOnPages: 2700,
//     userBrowser: "Edge",
//     userDeviceType: "Desktop",
//     userOs: "Windows",
//     latestPitchDeck: {},
//     latestVideo: {},
//     latestDeckDownload: { deckDownloadYes: true },
//     latestPassButton: { passYes: false },
//     latestConnectButton: { connectYes: true },
//     totalShares: 3,
//     lastVisit: Date.now() - 1500000,
//   },
//   {
//     email: "david@example.com",
//     review: {
//       email: "david@example.com",
//       overalRating: 2,
//       teamValue: 2,
//       problemValue: 3,
//       solutionValue: 2,
//       gtmstrategyValue: 1,
//       marketoppValue: 2,
//       details: "Concept needs significant work across all areas.",
//     },
//     totalVisits: 5,
//     totalTimeSpentOnPages: 900,
//     userBrowser: "Chrome",
//     userDeviceType: "Mobile",
//     userOs: "Android",
//     latestPitchDeck: {},
//     latestVideo: {},
//     latestDeckDownload: { deckDownloadYes: false },
//     latestPassButton: { passYes: true },
//     latestConnectButton: { connectYes: false },
//     totalShares: 0,
//     lastVisit: Date.now() - 2500000,
//   },
//   {
//     email: "eve@example.com",
//     review: {
//       email: "eve@example.com",
//       overalRating: 5,
//       teamValue: 5,
//       problemValue: 5,
//       solutionValue: 5,
//       gtmstrategyValue: 5,
//       marketoppValue: 5,
//       details: "Outstanding across all aspects. Highly recommend.",
//     },
//     totalVisits: 25,
//     totalTimeSpentOnPages: 6000,
//     userBrowser: "Firefox",
//     userDeviceType: "Desktop",
//     userOs: "macOS",
//     latestPitchDeck: {},
//     latestVideo: {},
//     latestDeckDownload: { deckDownloadYes: true },
//     latestPassButton: { passYes: false },
//     latestConnectButton: { connectYes: true },
//     totalShares: 10,
//     lastVisit: Date.now() - 100000,
//   },
//   {
//     email: "frank@example.com",
//     review: {
//       email: "frank@example.com",
//       overalRating: 3,
//       teamValue: 4,
//       problemValue: 3,
//       solutionValue: 3,
//       gtmstrategyValue: 2,
//       marketoppValue: 3,
//       details: "Good team, but the problem-solution fit is unclear.",
//     },
//     totalVisits: 10,
//     totalTimeSpentOnPages: 2100,
//     userBrowser: "Chrome",
//     userDeviceType: "Desktop",
//     userOs: "Linux",
//     latestPitchDeck: {},
//     latestVideo: {},
//     latestDeckDownload: { deckDownloadYes: true },
//     latestPassButton: { passYes: true },
//     latestConnectButton: { connectYes: false },
//     totalShares: 2,
//     lastVisit: Date.now() - 1800000,
//   },
//   {
//     email: "grace@example.com",
//     review: {
//       email: "grace@example.com",
//       overalRating: 4,
//       teamValue: 4,
//       problemValue: 5,
//       solutionValue: 4,
//       gtmstrategyValue: 3,
//       marketoppValue: 4,
//       details:
//         "Addressing a critical problem with a solid solution. GTM needs work.",
//     },
//     totalVisits: 18,
//     totalTimeSpentOnPages: 4200,
//     userBrowser: "Safari",
//     userDeviceType: "Mobile",
//     userOs: "iOS",
//     latestPitchDeck: {},
//     latestVideo: {},
//     latestDeckDownload: { deckDownloadYes: true },
//     latestPassButton: { passYes: false },
//     latestConnectButton: { connectYes: true },
//     totalShares: 6,
//     lastVisit: Date.now() - 800000,
//   },
//   {
//     email: "henry@example.com",
//     review: {
//       email: "henry@example.com",
//       overalRating: 3,
//       teamValue: 3,
//       problemValue: 4,
//       solutionValue: 3,
//       gtmstrategyValue: 3,
//       marketoppValue: 2,
//       details:
//         "Interesting problem, but solution and market opportunity need refinement.",
//     },
//     totalVisits: 7,
//     totalTimeSpentOnPages: 1500,
//     userBrowser: "Edge",
//     userDeviceType: "Tablet",
//     userOs: "Windows",
//     latestPitchDeck: {},
//     latestVideo: {},
//     latestDeckDownload: { deckDownloadYes: false },
//     latestPassButton: { passYes: false },
//     latestConnectButton: { connectYes: false },
//     totalShares: 1,
//     lastVisit: Date.now() - 2200000,
//   },
//   {
//     email: "isabel@example.com",
//     review: {
//       email: "isabel@example.com",
//       overalRating: 4,
//       teamValue: 5,
//       problemValue: 4,
//       solutionValue: 4,
//       gtmstrategyValue: 4,
//       marketoppValue: 3,
//       details:
//         "Excellent team with a good solution. Market opportunity could be larger.",
//     },
//     totalVisits: 22,
//     totalTimeSpentOnPages: 5100,
//     userBrowser: "Chrome",
//     userDeviceType: "Desktop",
//     userOs: "macOS",
//     latestPitchDeck: {},
//     latestVideo: {},
//     latestDeckDownload: { deckDownloadYes: true },
//     latestPassButton: { passYes: false },
//     latestConnectButton: { connectYes: true },
//     totalShares: 7,
//     lastVisit: Date.now() - 300000,
//   },
//   {
//     email: "jane@example.com",
//     review: {
//       email: "jane@example.com",
//       overalRating: 4,
//       teamValue: 4,
//       problemValue: 5,
//       solutionValue: 4,
//       gtmstrategyValue: 4,
//       marketoppValue: 3,
//       details:
//         "Excellent team with a good solution. Market opportunity could be larger.",
//     },
//     totalVisits: 22,
//     totalTimeSpentOnPages: 5100,
//     userBrowser: "Chrome",
//     userDeviceType: "Desktop",
//     userOs: "macOS",
//     latestPitchDeck: {},
//     latestVideo: {},
//     latestDeckDownload: { deckDownloadYes: true },
//     latestPassButton: { passYes: false },
//     latestConnectButton: { connectYes: true },
//     totalShares: 7,
//     lastVisit: Date.now() - 300000,
//   },
//   {
//     email: "jane@example.com",
//     review: {
//       email: "jane@example.com",
//       overalRating: 4,
//       teamValue: 4,
//       problemValue: 5,
//       solutionValue: 4,
//       gtmstrategyValue: 4,
//       marketoppValue: 3,
//       details:
//         "Excellent team with a good solution. Market opportunity could be larger.",
//     },
//     totalVisits: 22,
//     totalTimeSpentOnPages: 5100,
//     userBrowser: "Chrome",
//     userDeviceType: "Desktop",
//     userOs: "macOS",
//     latestPitchDeck: {},
//     latestVideo: {},
//     latestDeckDownload: { deckDownloadYes: true },
//     latestPassButton: { passYes: false },
//     latestConnectButton: { connectYes: true },
//     totalShares: 7,
//     lastVisit: Date.now() - 300000,
//   },
// ];

interface VisitorListFullProps {
  data: ReviewsDashBoard[];
  loading: boolean;
  error: string;
  searchTerm: string; // Add this prop
}

const VisitorsListFull: React.FC<VisitorListFullProps> = ({
  loading,
  error,
  searchTerm,
  data,
}) => {
  const { startupProfile } = useContext(AppContext);
  const userPlan = startupProfile?.plan || "basic";

  const isBasicPlan = userPlan === "basic";

  const [currentPage, setCurrentPage] = useState(1);
  const [paginatedData, setPaginatedData] = useState<ReviewsDashBoard[]>([]);
  const filteredData = useMemo(
    () =>
      data.filter((visitor) =>
        visitor.email.toLowerCase().includes(searchTerm.toLowerCase())
      ),
    [data, searchTerm]
  );

  const totalPages = Math.ceil(filteredData.length / ITEMS_PER_PAGE);

  useEffect(() => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    setPaginatedData(filteredData.slice(startIndex, endIndex));
  }, [currentPage, filteredData]);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };

  const visibleData = isBasicPlan ? paginatedData.slice(0, 4) : paginatedData;
  const hiddenDataCount = isBasicPlan
    ? Math.max(0, filteredData.length - 4)
    : 0;

  return (
    <>
      <Grid
        container
        display="flex"
        p={3}
        bgcolor="#012626"
        borderRadius="15px 15px 0 0"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          <Typography
            variant="h5"
            style={{
              color: "#04BF7B",
              fontWeight: "400",
            }}
          >
            Visitor's activity
          </Typography>
        </Box>
        {!isBasicPlan && (
          <CustomPagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
          />
        )}
      </Grid>

      <Grid
        container
        sx={{ padding: "20px 56px" }}
        display="flex"
        direction="row"
      >
        <Grid item xs={4}>
          <Typography variant="subtitle1">Visitors</Typography>
        </Grid>
        <Grid item xs={4} display="flex" justifyContent="center">
          <Typography variant="subtitle1">Total Visits</Typography>
        </Grid>
        <Grid item xs={4} display="flex" justifyContent="flex-end">
          <Typography variant="subtitle1">Last Visit</Typography>
        </Grid>
      </Grid>

      <Grid container sx={{ padding: "16px 48px", gap: 1 }}>
        {loading ? (
          <Typography variant="body1">Loading...</Typography>
        ) : visibleData.length > 0 ? (
          <>
            {visibleData.map((visitor: ReviewsDashBoard, index: number) => (
              <VisitorCard key={index} visitorData={visitor} />
            ))}
            {isBasicPlan && hiddenDataCount > 0 && (
              <Box sx={{ position: "relative", width: "100%", mt: 2 }}>
                <Box
                  sx={{
                    filter: "blur(4px)",
                    pointerEvents: "none",
                    backgroundColor: "rgba(255, 255, 255, 0.7)",
                  }}
                >
                  {data
                    .slice(4)
                    .map((visitor: ReviewsDashBoard, index: number) => (
                      <VisitorCard key={index} visitorData={visitor} />
                    ))}
                </Box>
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    bgcolor: "#ffffff",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "2rem",
                    borderRadius: "8px",
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <IoLockOpenOutline size={100} color="#04BF7B" />
                  <Typography
                    variant="h6"
                    style={{ textAlign: "center", margin: "1rem" }}
                  >
                    Unlock the Full List!
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{ textAlign: "center", marginBottom: "1rem" }}
                  >
                    Discover Additional {hiddenDataCount} Visitors Who Viewed
                    Your Startup!
                  </Typography>
                  <button
                    className="button__primary color__active"
                    style={{
                      backgroundColor: "#04BF7B",
                      color: "#fff",
                      padding: "10px 20px",
                      border: "none",
                      borderRadius: "5px",
                      cursor: "pointer",
                      transition: "background-color 0.3s",
                    }}
                    onMouseEnter={(e) =>
                      (e.currentTarget.style.backgroundColor = "#03a86b")
                    }
                    onMouseLeave={(e) =>
                      (e.currentTarget.style.backgroundColor = "#04BF7B")
                    }
                  >
                    Upgrade to Pro Now!
                  </button>
                </Box>
              </Box>
            )}
          </>
        ) : (
          <Typography variant="body1">No Visitors found.</Typography>
        )}
      </Grid>
    </>
  );
};

export default VisitorsListFull;
