import React, { FC, useState, useRef, useContext } from "react";
import { Grid, Box } from "@mui/material";
import { BiSolidPencil } from "react-icons/bi";
import ReactPlayer from "react-player/lazy";
import VideoInnerModal from "../ModalChoice/Modals/VideoInnerModal";
import { trackClickVideo } from "../../../../../helpers/Api";
import { getCookiesData } from "../../../../../helpers/Cookie";
import { AppContext } from "../../../../../context";
import MainCard from "../../../../../components/components/MainCard";

interface CardChoosingProps {
  id: string;
  startUpId?: number | string | null | undefined;
  videoUrl: string | undefined | null;
  isReadOnly?: boolean;
}

interface TrackEventData {
  email_as_user_id: string | null | undefined;
  user_browser: string | null | undefined;
  user_os: string | null | undefined;
  user_device_type: string | null | undefined;
  startup_id: number | string | null | undefined;
  total_time_spent_on_video: string | null | undefined;
  finished_video: boolean | null | undefined;
}

const CardVideo: FC<CardChoosingProps> = ({
  id,
  startUpId,
  videoUrl,
  isReadOnly = false,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [modalId, setModalId] = useState<string>("");
  const playerRef = useRef<ReactPlayer>(null);
  const { getStartupProfile } = useContext(AppContext);

  const openTypeModal = (id: string) => {
    setModalId(id);
    openModal();
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    getStartupProfile();
  };

  const trackVideoData = async (isFinished: boolean) => {
    const playedSeconds = playerRef.current?.getCurrentTime() || 0;
    const cookiesData = getCookiesData();
    const data: TrackEventData = {
      email_as_user_id: cookiesData.email,
      user_browser: cookiesData.browser,
      user_os: cookiesData.os,
      user_device_type: cookiesData.deviceType,
      startup_id: startUpId,
      total_time_spent_on_video: playedSeconds.toString(),
      finished_video: isFinished,
    };
    await trackClickVideo(data);
  };

  const handlePause = async () => {
    await trackVideoData(false);
  };

  const handleEnded = async () => {
    await trackVideoData(true);
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="stretch"
    >
      <Grid item md={12}>
        <MainCard title="Video">
          <Box sx={{ position: "relative", display: "flex" }}>
            <Grid
              container
              display={"flex"}
              direction="row"
              justifyContent="center"
              alignItems="center"
              m={2}
              pb={4}
              item
              xs={12}
            >
              <ReactPlayer
                url={videoUrl ?? undefined}
                controls={true}
                onPause={handlePause}
                onEnded={handleEnded}
                width={"100%"}
                height={"400px"}
                ref={playerRef}
              />
            </Grid>
            {!isReadOnly && (
              <button
                onClick={() => openTypeModal(id)}
                className="button__edit color__active"
                style={{
                  backgroundColor: "#04BF7B",
                  borderRadius: "8px",
                  padding: "8px",
                  cursor: "pointer",
                  transition: "background-color 0.3s ease",
                }}
              >
                <BiSolidPencil size={22} />
              </button>
            )}
          </Box>
        </MainCard>
      </Grid>
      <VideoInnerModal
        modalIsOpen={modalIsOpen && !isReadOnly}
        userId={modalId}
        closeModal={closeModal}
      />
    </Grid>
  );
};

export default CardVideo;
