import instance from ".";

export const getTemporalInvestors = async (searchQuery: string, page: number, startupId: number | string) => {
  try {
    const response = await instance.get(`/startup/${startupId}/temporal-investors/`,{
      params: {
        search: searchQuery,
        page: page,
      },
    });
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const getFavoritesInvestors = async (searchQuery: string, startupId: number | string) => {
  try {
    const response = await instance.get(`/startup/${startupId}/investments/favorites/`,{
      params: {
        search: searchQuery,
      },
    });
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const getFavInvestors = async (searchQuery: string, page: number, startupId: number | string) => {
  try {
    const response = await instance.get(`/startup/${startupId}/investments/favorites/`,{
      params: {
        search: searchQuery,
        page: page,
      },
    });
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const addInvestorToFav = async (startupId: number | string, investorId: number) => {
  try {
    const response = await instance.post(`/startup/${startupId}/investments/favorites/${investorId}/edit/`);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const removeInvestorFromFav = async (startupId: number | string, investorId: number) => {
  try {
    const response = await instance.delete(`/startup/${startupId}/investments/favorites/${investorId}/edit/`);
    return response.data;
  } catch (err) {
    throw err;
  }
};