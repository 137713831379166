import React, { FC } from 'react';
import { useNavigate } from "react-router-dom";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import Avatar from "@mui/material/Avatar";
import { HiOutlineRocketLaunch, HiOutlineUserCircle } from "react-icons/hi2";
import { IoSettingsOutline, IoShareSocialOutline, IoGridOutline } from "react-icons/io5";
import { TbMoneybag } from 'react-icons/tb';


interface LocationState {
  title: string,
  navRoute: string,
  isActive: boolean,
  disabled?:boolean
}

const ButtonNavActive: FC<LocationState> = ({navRoute, title, isActive, disabled}) => {

  const getIcon = () => {
    switch (navRoute) {
      case "/dashboard":
        return <IoGridOutline size={23} color="rgba(4, 191, 123, 1)" />
      case "/startup-profile/UserProfile":
        return <HiOutlineRocketLaunch size={23} color="rgba(4, 191, 123, 1)" />
      case "/profile":
        return <HiOutlineUserCircle size={23} color="rgba(4, 191, 123, 1)" />
      case "/settings":
        return <IoSettingsOutline color="rgba(4, 191, 123, 1)"/>
      case "/fundraising":
        return <TbMoneybag color="rgba(4, 191, 123, 1)"/>
      default:
        return null;
    }
  }

  const navigate = useNavigate();
  return (
    <ListItemButton
    onClick={() => navigate(navRoute)}
    disabled={disabled}
    selected={isActive}
     sx={{
      ":hover": {
        backgroundColor: "rgba(231, 248, 235, 1)",
      },
      borderRadius: '6px',
      "&.Mui-foc": {

      },
      "&.Mui-selected": {
        backgroundColor: "rgba(231, 248, 235, 1)",
      }
    }}
    >
      <ListItemIcon>
        <Avatar sx={{ bgcolor: "rgba(231, 248, 235, 1)", borderRadius: '10px' }}>
        {getIcon()}
        </Avatar>
      </ListItemIcon>
      {isActive ?
      <ListItemText sx={{color: "rgba(4, 191, 123, 1)"}}
      primary={title} />
      :
      <ListItemText sx={{
        ":hover": {
          color: "rgba(4, 191, 123, 1)",
        },
      }}
      primary={title} />
      }
    </ListItemButton>
  )
}

export default ButtonNavActive